import axios from 'axios';
import React, { useState ,forwardRef, useRef, useEffect } from 'react'
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import banner from '../../images/banners/login.jpg'
import logo from '../../images/logo-purple.png'
import { country_code_array } from './functions';

 

export default function AwblabelAramex() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    let newDate = new Date()
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
const today=`${year}/${month}/${date}`
const [parcelcount, setparcelcount] = useState(1)
    const location = useLocation();
    const [order, setorder] = useState('')
    const [parcelcontent, setparcelcontent] = useState('')
    const [countries, setcountries] = useState(country_code_array);
    const [codebar, setcodebar] = useState(1)
    const [orderdata, setorderdata] = useState(false)
    const [downloadlink, setdownloadlink] = useState()
    useEffect(() => {
      if(order==""){

     
        console.log(location.state.awbdetail)
    const API_PATH2=`${SERVER_URL}get_order_details.php`;
    axios({
        method: 'post',
        url: `${API_PATH2}`,
        data: location.state.orderno
      })
      .then((res)=>{
    
    if(res.data !=""){
      setorderdata(true);
    
      setorder(JSON.parse(JSON.stringify(res.data)))
      if(res.data.parcel!=""){
     setparcelcontent(res.data.parcel)
      setparcelcount(res.data.parcel.length)
      }
      setdownloadlink('https://timexpress.ae/label/pdf-download-awbno.php?awb='+location.state.awbdetail.awbdetails.awbNo)
      Afterdata(res.data.collectiondetails.email,res.data.orderdetails.total_price)
   
    }
  
      })
      .catch((err)=>console.log(err))
    }else{
     
    }
  }, [])

 
      const ref = useRef();
      const ref1 = useRef();

      function Afterdata(emailid,total_price1){ 
        window.dataLayer = window.dataLayer || [];  
        window.dataLayer.push({
          'event':'purchase_confirmed',
          'order_value':total_price1,
          'order_id':location.state.orderno,
          'email': emailid
         });
     
        
      }

  return (
    <div class="awblabel ">
        <Helmet>
        <title>Time Express | Order Confirmation</title>
      </Helmet>
        <section className="your_business_page fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
        <div className="container"><div className="row">
          <div className="col-md-12 col-sm-12 your_business text-center">
            <div className="business_content">
            <h1 className='h1-title'>ORDER DETAIL</h1>

            </div>
          </div>
        </div>
        </div>
      </section>

     <style type="text/css" media="print" dangerouslySetInnerHTML={{__html: "\n  @page { size: landscape; }\n   @page { margin: 0; size: A4;}\n   @media print {\n  footer {\n    position: fixed;\n    bottom: 0;\n  }\n\n  .content-block, p {\n    page-break-inside: avoid;\n  }\n\n  html, body {\n    width: 210mm;\n    height: 297mm;\n  }\n}\n" }} />
   
<div className=" parsel_container pages_content" >
<p className="received_text" style={{textAlign: 'left'}}>
Please print the label and attach to your package, our driver will come shortly to collect your parcel. A PDF copy of your label is also available to download from your confirmation email.</p>

{
                location.state.awbdetail.Aramex_label?(
                  <p className="received_text" style={{textAlign: 'left'}}><a href={location.state.awbdetail.Aramex_label} download="Label" target="_blank">Download / Print Label </a></p>

                ):null
              }
      <div className="servies-content become_driver last_order thank-you-page">
        {/* <div className="print-btn">
          <input type="button" data_id="print_<?php echo $label; ?>" id="btnPrint<?php echo $label; ?>" defaultValue="Print" />
        </div> */}

{/* <ReactToPrint content={() => ref.current}>
        <PrintContextConsumer>
          {({ handlePrint }) => (
            <button onClick={handlePrint} className="btn btn-small" style={{marginBottom:"2%",marginTop:"2%",background:"#7f3f98",color:"#fff",minWidth:"100px"}}>Print</button>
          )}
        </PrintContextConsumer>
      </ReactToPrint> */}
      {location.state.awbdetail.Aramex_label?(
//  <iframe src={location.state.awbdetail.Aramex_label} style={{width: "100%",height: "600px",border:"none"}}></iframe>
<div className="table-responsive " ref={ref}>
 <embed src={`${location.state.awbdetail.Aramex_label}#toolbar=0`} style={{width: "100%",height: "600px",border:"none"}}></embed>
 </div>
):null}
      
       
          
     
     
        
        </div>
        </div>
        </div>
    

      
  
  )
}
