import React from 'react'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import banner from '../../images/banners/corporate_sme.jpg'
import loading from '../../images/spin.gif'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faFileCsv, faMedal, faRulerHorizontal,faFileImport,faCubesStacked,faUser } from '@fortawesome/free-solid-svg-icons'
const circle = <FontAwesomeIcon icon={faUser} className="bulkicons" />
const csvfile = <FontAwesomeIcon icon={faFileImport} className="bulkicons" />
const medal = <FontAwesomeIcon icon={faCubesStacked} className="bulkicons" />
const dash = <FontAwesomeIcon icon={faRulerHorizontal} />


export default function Corporate_sme() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [register, setregister] = useState(
    {
      username: '',
      email: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      password: '',
      dom_parcels: '',
      int_parcels: '',
      company_name: '',
      confirm_password: ''
    }
  )
  const [passerror, setpasserror] = useState()
  const [passsuccess, setpasssuccess] = useState()
  const [error, seterror] = useState()
  const [loadingshow, setloadingshow] = useState(false)
  const [nextpage, setnextpage] = useState(0)
  function checkpass(val) {
    console.log(register.password)
    if (val != register.password) {
      console.log('error');
      setpasserror('password mismatch')

    } else {
      setregister({ ...register, confirm_password: val })
      setpasserror('')
    }
  }

  function submitregister() {

    setnextpage(1)
    let emailerror = 0;
    const API_PATH = `${SERVER_URL}corporate_register.php`;
    if (register.username != "" && register.password != "" && register.confirm_password != "" && register.email != "" && register.company_name != "" && register.dom_parcels != "" && register.int_parcels != "" && register.phone_number != "" && image_info.filedetails.company_licence != "" && image_info.filedetails.passport != "") {
     
      let lastAtPos = register.email.lastIndexOf("@");
      let lastDotPos = register.email.lastIndexOf(".");

      if (!(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        register.email.indexOf("@@") == -1 &&
        lastDotPos > 2 &&
        register.email.length - lastDotPos > 2
      )) {
        emailerror = 1

        setpasserror('Please enter a valid email id')
      } else {
        setpasserror()
        emailerror = 0
      }
      if (emailerror == 0) {  setloadingshow(true)
        axios({
          method: 'post',
          url: `${API_PATH}`,
          data: register
        })
          .then((res) => {
            setloadingshow(false)
            if (res.data.code == 0) {

              setpasserror(res.data.message)
            } else {
              sessionStorage.setItem('userid', res.data.userid);
              sessionStorage.setItem('corporate_customer',1);
              // setTimeout(
              //  // () => navigate('/my-account/my-parcels'), 
              //   3000
              // );
              imageupload(res.data.userid)

            }

          }
          )
          .catch((err) => console.log(err))
      }

    } else {
      seterror("Please enter all the required fields")
    }



  }

  const [image_info, setimage_info] = useState({
    filedetails: {
      company_licence: '',
      passport: ''
    }

  })
  console.log(image_info.filedetails)

  function updatefiles(val, k) {
    //const updatedCount = 1;
    const clonedListing = { ...image_info.filedetails };
    //const updatedAccepted = [...clonedListing.image]
    // updatedAccepted[i] = val;
    if (k == 'company_licence') {
      setimage_info({
        filedetails: {
          ...clonedListing,
          company_licence: val
        }
      });
    } else if (k == 'passport') {
      setimage_info({
        filedetails: {
          ...clonedListing,
          passport: val
        }
      });
    }
  }

  function imageupload(userid) {
    const data = new FormData();
    data.append("company_licence[]", image_info.filedetails.company_licence);
    data.append("passport[]", image_info.filedetails.passport);
    data.append("userid", userid);
    let url = `${SERVER_URL}corporate_fileupload.php`;

    axios.post(url, data, {
      // receive two parameter endpoint url ,form data
    })
      .then((res) => {
        console.log('output ', res.data)
        setloadingshow(false)
        if (res.data == '0') {

          setpasssuccess('You are successfully registered. Please check your email.')
          //setmsg('Error Uploading files')
          //   setmsgcolor('red')
        } else {
          seterror('There are error uploading file. please check it')
          //setmsg('Driver Details Added Successfully')
          //setmsgcolor('green')


        }
        //  navigate('/drivers')
        // then print response status

      }, error => {
        alert(error);


      });

  }
  return (

    <div>
      <Helmet>
        <title>Time Express | Corporate SME Register</title>
      </Helmet>
      <section className="your_business_page fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
        <div className="container"><div className="row">
          <div className="col-md-12 col-sm-12 your_business text-center">
            <div className="business_content">
              <h1 className="mainhead">CORPORATE SME REGISTER</h1>

            </div>
          </div>
        </div>
        </div>
      </section>
      <div className="pages_content">
        <div className="container">
       
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10 loginbox" style={{ marginTop: "6%", marginBottom: "6%" }}>



            <div className="row" style={{ textAlign: 'center' }}>
            <div className="col-md-4 circle" style={{ position: "relative" }}>
              {circle}
              <p style={{ fontSize: '13px' }}>Sign in/ Register with your Time Express corporate account</p>
            </div>
            <div className="col-md-4 circle" style={{ position: "relative" }}>
              {csvfile}
              <p style={{ fontSize: '13px' }}>Upload your CSV with Bulk Upload</p>
            </div>
            <div className="col-md-4">
              {medal}
              <p style={{ fontSize: '13px' }}>Start sending multiple parcels in one go!</p>
            </div>

          </div>

              <div id="primary" className="content-area" style={{ float: 'left', padding: '3%', width: "100%" }}>


                <main id="main" className="site-main" role="main">
                  <p>Your week just got better. We are offering up to 35% discount for businesses.  No commitment. No fees. Get cooking now: to avail your discount simply complete the below form, thanks for signing up!</p>
                  <h5 style={{ color: '#7b3995' }}><b>How it works?</b></h5>
                  <p>Our corporate customers figured out a smarter way to send parcels, cheaper and more conveniently. If you are a registered company and want the best parcel prices you’ve come to the right place!  There’s no charge to sign up. Just use the below form to register to get preferential rates with up to 35% discount on domestic and international parcels.  You will need to upload your company license and passport/emirates ID copy.  You will then receive an email with your login details, your discount will be automatically applied at time of making your bookings.  </p>
                  <p>Note: Customers who are not companies without a license are not eligible for preferential corporate rates. </p>
                  <br />

                  <form>

                    <div className="row">
                      <div className="col-md-6">
                        <input type="text" placeholder='Name' className={nextpage == 0 ? 'form-control mb-3' : nextpage == 1 && register.first_name.length < 1 ? 'error form-control mb-3' : 'form-control mb-3'} required onChange={(e) => setregister({ ...register, first_name: e.target.value })} />
                      </div>
                      <div className="col-md-6">
                        <input type="text" placeholder='Company Name' className={nextpage == 0 ? 'form-control mb-3' : nextpage == 1 && register.company_name.length < 1 ? 'error form-control mb-3' : 'form-control mb-3'} required onChange={(e) => setregister({ ...register, company_name: e.target.value })} />

                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <input type="email" placeholder='Email' className={nextpage == 0 ? 'form-control mb-3' : nextpage == 1 && register.email.length < 1 ? 'error form-control mb-3' : 'form-control mb-3'} required onChange={(e) => setregister({ ...register, email: e.target.value })} />

                      </div>
                      <div className="col-md-6">
                        <input type="text" placeholder='Phone Number' className={nextpage == 0 ? 'form-control mb-3' : nextpage == 1 && register.phone_number.length < 1 ? 'error form-control mb-3' : 'form-control mb-3'} required onChange={(e) => setregister({ ...register, phone_number: e.target.value })} />

                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <input type="text" placeholder='Username' className={nextpage == 0 ? 'form-control mb-3' : nextpage == 1 && register.username.length < 1 ? 'error form-control mb-3' : 'form-control mb-3'} required onChange={(e) => setregister({ ...register, username: e.target.value })} />

                      </div>
                      <div className="col-md-6">
                        <input type="password" placeholder='Password' className={nextpage == 0 ? 'form-control mb-3' : nextpage == 1 && register.password.length < 1 ? 'error form-control mb-3' : 'form-control mb-3'} required onChange={(e) => setregister({ ...register, password: e.target.value })} />

                      </div>
                    </div>



                    <input type="password" placeholder='Confirm Password' className={nextpage == 0 ? 'form-control mb-3' : nextpage == 1 && register.confirm_password.length < 1 ? 'error form-control mb-3' : 'form-control mb-3'} required onChange={(e) => checkpass(e.target.value)} />
                    <p style={{ color: 'red' }}>{passerror}</p>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Parcels Send Per Month</label>
                        <div className="row">
                          <div className="col-md-6">
                            <select className={nextpage == 0 ? 'form-control mb-3' : nextpage == 1 && register.dom_parcels.length < 1 ? 'error form-control mb-3' : 'form-control mb-3'} name="dom_parcels" onChange={(e) => setregister({ ...register, dom_parcels: e.target.value })}>
                              <option>Select Domestic Parcels</option>
                              <option value="0">0</option>
                              <option value="50">1-50</option>
                              <option value="100">51-100</option>
                              <option value="100+">100+</option>
                            </select>
                          </div>
                          <div className="col-md-6">
                            <select className={nextpage == 0 ? 'form-control mb-3' : nextpage == 1 && register.int_parcels.length < 1 ? 'error form-control mb-3' : 'form-control mb-3'} name="int_parcels" onChange={(e) => setregister({ ...register, int_parcels: e.target.value })}>
                              <option>Select International Parcels</option>
                              <option value="0">0</option>
                              <option value="5">1-50</option>
                              <option value="15">51-100</option>
                              <option value="15+">100+</option>
                            </select></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Upload Company License</label>
                        <input type="file" className={nextpage == 0 ? 'form-control mb-3' : nextpage == 1 && image_info.filedetails.company_licence.length < 1 ? 'error form-control mb-3' : 'form-control mb-3'} onChange={(e) => updatefiles(e.target.files[0], 'company_licence')} id="" name="fileToUpload[]" />
                      </div>
                    </div>

                    <div className="col-md-12" style={{ marginBottom: '3%' }}>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Upload Emirates ID / Passport Copy</label>
                        <input type="file" className={nextpage == 0 ? 'form-control mb-3' : nextpage == 1 && image_info.filedetails.passport.length < 1 ? 'error form-control mb-3' : 'form-control mb-3'} onChange={(e) => updatefiles(e.target.files[0], 'passport')} id="" name="fileToUpload[]" />
                      </div>
                    </div>

                    <p style={{ color: 'red' }}>{error}</p>
                    <p style={{ color: 'purple' }}>{passsuccess}</p>
                    {/* <table className='table tableBordered' style={{border:'1px solid'}} id="discounts">
    <thead style={{background:'#7b3995',color:'#fff'}}>
        <tr>
            <th>Domestic</th>
            <th>Discount</th>
            <th>International</th>
            <th>Discount</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>{'<50'}</td>
            <td>10%</td>
            <td>{'<5'}</td>
            <td>20%</td>
        </tr>
        <tr style={{background:'#7b39951c'}}>
            <td>{'51<>100'}</td>
            <td>20%</td>
            <td>{'6<>15'}</td>
            <td>25%</td>
        </tr>
        <tr>
            <td>{'>101'}</td>
            <td>35%</td>
            <td>{'>16'}</td>
            <td>35%</td>
        </tr>
    </tbody>
</table> */}

                    <button type="button" className="btn  btn-sm" onClick={submitregister} style={{ backgroundColor: '#7b3995', color: "#fff", padding: "1%", width: "19%" }}>Register</button>
                  </form>

                  {loadingshow ? (
                    <p style={{ textAlign: "center", marginTop: "2%" }}><img src={loading} style={{ width: "5%" }} /></p>
                  ) : null}
                </main>
              </div>

              <h6><b>TERMS & CONDITIONS</b></h6>
              <p>-	Client hereby authorizes Time Express to validate his application.</p>
              <p>-	The preferential corporate rates are valid only for commercial companies.</p>
              <p>-	Time Express has the right to revoke your access if company details are invalid.</p>

            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
