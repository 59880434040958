import React from 'react'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import banner from '../../images/banners/corporate_sme.jpg'
import loading from '../../images/spin.gif'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faFileCsv, faMedal, faRulerHorizontal,faFileImport,faCubesStacked,faUser } from '@fortawesome/free-solid-svg-icons'
const circle = <FontAwesomeIcon icon={faUser} className="bulkicons" />
const csvfile = <FontAwesomeIcon icon={faFileImport} className="bulkicons" />
const medal = <FontAwesomeIcon icon={faCubesStacked} className="bulkicons" />
const dash = <FontAwesomeIcon icon={faRulerHorizontal} />


export default function Staff_discount() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [register, setregister] = useState(
    {
      username: '',
      email: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      password: '',
      dom_parcels: '',
      int_parcels: '',
      company_name: '',
      confirm_password: ''
    }
  )
  const [passerror, setpasserror] = useState()
  const [passsuccess, setpasssuccess] = useState()
  const [error, seterror] = useState()
  const [loadingshow, setloadingshow] = useState(false)
  const [nextpage, setnextpage] = useState(0)
  function checkpass(val) {
    console.log(register.password)
    if (val != register.password) {
      console.log('error');
      setpasserror('password mismatch')

    } else {
      setregister({ ...register, confirm_password: val })
      setpasserror('')
    }
  }

  function submitregister() {
    setnextpage(1)
    let emailerror = 0;
   const API_PATH = `${SERVER_URL}staff_register.php`;
    if (register.username != "" && register.password != "" && register.confirm_password != "" && register.email != "" && register.company_name != "" && register.phone_number != "" ) {
     
      let lastAtPos = register.email.lastIndexOf("@");
      let lastDotPos = register.email.lastIndexOf(".");

      if (!(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        register.email.indexOf("@@") == -1 &&
        lastDotPos > 2 &&
        register.email.length - lastDotPos > 2
      )) {
        emailerror = 1

        setpasserror('Please enter a valid email id')
      } else {

        if ((/@yahoo.com\s*$/.test(register.email)) || (/@gmail.com\s*$/.test(register.email))|| (/@hotmail.com\s*$/.test(register.email)) || (/@live.com\s*$/.test(register.email))) {
            setpasserror('Please enter your official company email id')
            emailerror = 1
         }else{
            setpasserror()
            emailerror = 0
         } 

       
      }
      if (emailerror == 0) {  setloadingshow(true)
        axios({
          method: 'post',
          url: `${API_PATH}`,
          data: register
        })
          .then((res) => {
            setloadingshow(false)
            if (res.data.code == 0) {

              setpasserror(res.data.message)
            } else {
              sessionStorage.setItem('userid', res.data.userid);
              sessionStorage.setItem('corporate_customer',1);
              // setTimeout(
              //  // () => navigate('/my-account/my-parcels'), 
              //   3000
              // );
              setpasssuccess('You are successfully registered. Please check your email.')

            }

          }
          )
          .catch((err) => console.log(err))
      }

    } else {
        setpasserror("Please enter all the required fields")
    }



  }

 



  return (

    <div>
      <Helmet>
        <title>Time Express | Corporate SME Register</title>
      </Helmet>
     
      <div className="pages_content staffdiscount">
        <div className="container">
       
        <section className="vh-100" style={{backgroundColor: '#eee'}}>
        <div className="container h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-lg-12 col-xl-11">
              <div className="card text-black" style={{borderRadius: '25px'}}>
                <div className="card-body p-md-5">
                  <div className="row justify-content-center">
                    <div className="col-md-10 col-lg-6 col-xl-6 order-2 order-lg-1">
                      <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Register</p>
                      <form className="px-md-2">
                  <div className="row">
                      <div className="col-md-6">
                        <input type="text" placeholder='Name' className={nextpage == 0 ? 'form-control mb-3' : nextpage == 1 && register.first_name.length < 1 ? 'error form-control mb-3' : 'form-control mb-3'} required onChange={(e) => setregister({ ...register, first_name: e.target.value })} />
                      </div>
                      <div className="col-md-6">
                        <input type="text" placeholder='Company Name' className={nextpage == 0 ? 'form-control mb-3' : nextpage == 1 && register.company_name.length < 1 ? 'error form-control mb-3' : 'form-control mb-3'} required onChange={(e) => setregister({ ...register, company_name: e.target.value })} />

                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <input type="email" placeholder='Email' className={nextpage == 0 ? 'form-control mb-3' : nextpage == 1 && register.email.length < 1 ? 'error form-control mb-3' : 'form-control mb-3'} required onChange={(e) => setregister({ ...register, email: e.target.value })} />

                      </div>
                      <div className="col-md-6">
                        <input type="text" placeholder='Phone Number' className={nextpage == 0 ? 'form-control mb-3' : nextpage == 1 && register.phone_number.length < 1 ? 'error form-control mb-3' : 'form-control mb-3'} required onChange={(e) => setregister({ ...register, phone_number: e.target.value })} />

                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <input type="text" placeholder='Username' className={nextpage == 0 ? 'form-control mb-3' : nextpage == 1 && register.username.length < 1 ? 'error form-control mb-3' : 'form-control mb-3'} required onChange={(e) => setregister({ ...register, username: e.target.value })} />

                      </div>
                      <div className="col-md-6">
                        <input type="password" placeholder='Password' className={nextpage == 0 ? 'form-control mb-3' : nextpage == 1 && register.password.length < 1 ? 'error form-control mb-3' : 'form-control mb-3'} required onChange={(e) => setregister({ ...register, password: e.target.value })} />

                      </div>
                    </div>



                    <input type="password" placeholder='Confirm Password' className={nextpage == 0 ? 'form-control mb-3' : nextpage == 1 && register.confirm_password.length < 1 ? 'error form-control mb-3' : 'form-control mb-3'} required onChange={(e) => checkpass(e.target.value)} />
                    <p style={{ color: 'red' }}>{passerror}</p>
                    <p style={{ color: 'purple' }}>{passsuccess}</p>
                    <button type="button" className="btn  btn-sm" onClick={submitregister} style={{ backgroundColor: '#7b3995', color: "#fff", padding: "1%", width: "19%" }}>Register</button>
                
                  

                  
                  </form>
                  {loadingshow ? (
                    <p style={{ textAlign: "center", marginTop: "2%" }}><img src={loading} style={{ width: "5%" }} /></p>
                  ) : null}
                    </div>
                    <div className="col-md-10 col-lg-6 col-xl-6 d-flex align-items-center order-1 order-lg-2">
                      <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp" className="img-fluid" alt="Sample image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        </div>
      </div>
    </div>
  )
}
