import React from 'react'
import { useNavigate, Link, useParams } from 'react-router-dom'
import icon1 from '../../../images/home/icon1.png'
import icon2 from '../../../images/home/icon2.png'
import icon4 from '../../../images/home/icon4.png'
import icon5 from '../../../images/home/icon5.png'
import service from '../../../images/home/pic-15.jpg'
import wallet from '../../../images/home/19.jpg'
import howit from '../../../images/home/23.jpg'
import timeservice from '../../../images/home/9.jpg'
import icon6 from '../../../images/home/icon6.png'
import icon7 from '../../../images/home/icon7.png'
import icon8 from '../../../images/home/icon8.png'
import expert from '../../../images/home/expert.png'
import expert2 from '../../../images/home/expert2.jpg'
import veh from '../../../images/home/18.jpg'
import partner from '../../../images/home/14.jpg'
import drive_icon from '../../../images/home/drive-icon.png'
import branch from '../../../images/home/branch.png'
import google from '../../../images/home/google.png'
import Testimonial from '../../Home/Testimonial'

export default function Parcels_delivered() {
  return (
    <>
  
      {/*Parcels. Delivered Yesterday.*/}
      <div style={{ height: '120px', background: '#fff' }} />
      <section className="home-section-1 parcelsdelivered">
        <div className="container">
          <div className="row">
            <h3 className="sec-1-title">Parcels. Delivered Yesterday.</h3>
            <div className="col-md-4">
              <div className="cont-holder">
                <span>
                  <img className=" lazyloaded" src={icon1} data-src={icon1} alt="" height={65} width={65} />
                </span>
                <div className="cont-1">
                  <Link to="/track/schedule-your-pick-up-and-delivery-time">
                    <h3 className>Schedule Your Pick-Up and Delivery Time</h3>
                  </Link>
                  <p className="paragrph">Schedule pickup &amp; Delivery Shipping Convenience. Depending on the service…</p>
                </div>
              </div>
              <div className="cont-holder">
                <span>
                  <img className=" lazyloaded" src={icon4} data-src={icon4} alt="" height={65} width={65} />
                </span>
                <div className="cont-1">
                  <Link to="/send">
                    <h3 className>Get a Quote</h3>
                  </Link>
                  <p className="paragrph">Save 40% on your courier service and air freight door to door.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pdy-img-holder">
                <img src={service} data-src={service} alt="" width={360} height={367} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="cont-holder">
                <span>
                  <img className=" lazyloaded" src={icon2} data-src={icon2} alt="" height={65} width={65} />
                </span>
                <div className="cont-1">
                  <Link to="/international-courier-services">
                    <h3 className>International Courier Services</h3>
                  </Link>
                  <p className="paragrph">INTERNATIONAL COURIER SERVICES International Courier Delivery Services If you need to send a courier package…</p>
                </div>
              </div>
              <div className="cont-holder">
                <span>
                  <img className=" lazyloaded" src={icon5} data-src={icon5} alt="" height={65} width={65} />
                </span>
                <div className="cont-1">
                  <a href="/sales-enquries">
                    <h3 className>Sales Enquiries</h3>
                  </a>
                  <p className="paragrph">Let's understand your business in more detail.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Parcels. Delivered Yesterday.*/}
      <div style={{ height: '70px', background: '#fff' }} />
      {/*Section 2*/}
      <section className="home-section-2 homesec2">
        <div className="container">
          <div className="row">
            {/*<h3 class="">Parcels. Delivered Yesterday.</h3>*/}
            <div className="col-md-4">
              <div className="sec-2-img">
                <img width={391} height={301} src={howit} data-src={howit} />
              </div>
              <div className="sec-2-content">
                <h3 className>How It Works?</h3>
                <p className>If you need to send a courier package to a friend, a business,…</p>
                <Link className to="/how-it-works">More</Link>
              </div>
              <div className="sec-2-icon">
                <span>
                  <img className src={icon6} data-src={icon6} alt={1} width={65} height={65} />
                </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="sec-2-content">
                <h3 className>Times Services</h3>
                <p className>We’re focused on growing your business while staying lean.…</p>
                <Link className to="/send/local-courier-same-day-courier/">More</Link>
              </div>
              <div className="sec-2-img">
                <img width={391} height={301} src={timeservice} data-src={timeservice} />
              </div>
              <div className="sec-2-icon">
                <span>
                  <img className src={icon7} data-src={icon7} alt={1} width={65} height={65} />
                </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="sec-2-img">
                <img width={391} height={301} src={wallet} data-src={wallet} />
              </div>
              <div className="sec-2-content">
                <h3 className>Open a wallet account</h3>
                <p className>Open a wallet account Discounts on pre-payment 6 EASY STEPS…</p>
                <Link className to="/open-a-wallet-account">More</Link>
              </div>
              <div className="sec-2-icon">
                <span>
                  <img className src={icon8} data-src={icon8} alt={1} width={65} height={65} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Section 2*/}

      {/*Section 3*/}
      <div className="home-section-3" style={{overflow:"hidden"}}>
        <div className="container">
          <div className="row">
            <h3 className>Shop<span>USA</span>, ship<span>UAE</span></h3>
            <section className="sec-3-content">
              <p className>Shop and send what you love from the USA to your door step</p>
              <div className>
                <Link className="sec-3-btn" to="shop-usa-ship-uae/">find out more</Link>
              </div>
            </section>
          </div>
        </div>
      </div>
      {/*End Section 3*/}

      {/*Section 4*/}
      <section className="home-section-4">
        <div className="container">
          <div className="row">
            <h3 className>Let us help you with your business</h3>
            <p>Time would like to reward you for sending more parcels. So what are you waiting for.....</p>
            <div className="col-md-12 sec-4-holder">
              <div className="sec-4-img-1">
                <img width={605} height={372} src={expert} data-src={expert} />
              </div>
              <div className="sec-4-content">
                <h2>I'M AN EXPERT, <br />SO I EXPECT <br />EXPERT HELP</h2>
                <Link to="/your-business">YOUR BUSINESS</Link>
              </div>
              <div className="sec-4-img-2">
                <div className>
                  <img src={expert2} data-src={expert2} alt="help-img1" height={373} width={618} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Section 4*/}
      {/*Section 5*/}
      <section className="home-section-5">
        <div className="container">
          <div className="row">
            <h3 className="sec-5-title">Join Our Community</h3>
            <div className="sec-5-content">
            <Link to="/become-a-driver/">
              <div className="grid-col">
                <div className="sec-5-img">
                  <img width={415} height={252} src={veh} data-src={veh} />
                </div>
                <div className="sec-5-img-2">
                  
                    <img src={drive_icon} data-src={drive_icon} alt="help-img1" />
                 
                  <h3>Become a Driver</h3>
                </div>
              </div>
              </Link>
              <Link to="/become-a-partner/">
              <div className="grid-col">
                <div className="sec-5-img">
                  <img width={415} height={252} src={partner} data-src={partner} />
                </div>
                <div className="sec-5-img-2">
                 
                    <img src={branch} data-src={branch} alt="help-img1" />
                  
                  <h3>Become a Partner</h3>
                </div>
              </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/*End Section 5*/}
      <section className="testmon pb-5" style={{ background: "#d0d0d0" }}>
        <div className="container">
          <div className="row text-center">
            <h3 className="help-let_h3 rev-go">WHAT OUR CUSTOMERS ARE SAYING</h3>
          <a href="https://g.page/r/CbwSwB8ZdZsOEAg/review" target="_blank"><img src={google} style={{ width: "8%" }} /></a>  
          </div>

        </div>
        <Testimonial />
      </section>
    </>
  )
}
