import React from 'react'
import { Helmet } from 'react-helmet';
import banner from '../../images/banners/about.jpg'
export default function About_us() {
  return (
    <div>
            <Helmet>
        <title>Time Express | About US</title>
      </Helmet>
    <section className="your_business_page fw" style={{backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
         <div className="container"><div className="row">
             <div className="col-md-12 col-sm-12 your_business text-center">
                 <div className="business_content">
                 <h1 className='h1-title'>About us</h1>
                     <h2 style={{display:'none'}}>About us</h2>
                   
                 </div>
             </div>
         </div>
         </div>
     </section>
     <div className="pages_content">
     <div className="container">
     <div className="row">
     <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
     <main id="main" className="site-main" role="main">
    <div className="entry-content">
    <div className="become_driver">
        <p>
        Time Express makes deliveries happen faster for businesses and consumers. We’ve developed a unique same day and next day delivery network positioned to enhance the speed and flexibility of last-mile delivery. We help consumers send international parcels more cost effectively and get products more quickly while lowering the cost of distribution for the businesses they buy from. In addition to being an ideal delivery partner for e-retailers and e-commerce companies, we offer expertise in wide range of solutions across wide range of industries that support time-critical delivery ranging from business to business, banks and financial services, to healthcare logistics. We are part of the Al Rais Enterprises Group, a conglomerate of various industries ranging from aircraft handling, inflight catering, travel services, retail, to airfreight cargo and express deliveries. We share a passion for great service, leveraged by the use of our in house systems to monitor each aspect of your parcel journey with our drivers to ensure a reliable and on time delivery seven days a week, enabling our expanding delivery couriers to reach more people every day.
        </p>
    <h3>Our philosophy comes down to focusing on a few key points:</h3>
    <h5>Communicate.</h5>
    <p>Delivering fast for a great price is only half the equation; it must also be easy for you to know what’s happening with your delivery. Our responsive staff keeps you informed every step of the way. You can see what’s happening with your shipment at all times, and can speak to our great staff any time more information is needed. We want the Time Express name to be something you feel really good about.</p>
    <h5>Care.</h5>
    <p>We really love what we do. Most of us at Time Express have been together for ten years or more. We’re doing something that helps people get products faster with less cost and hassle. We care about getting your shipment to you on time, at a great price, by people who enjoy their work.</p>
    <h5>Provide Great Customer Service.</h5>
    <p>Our easy to reach customer service department won’t keep you waiting on hold for an eternity. We’ll reply promptly if you send us an email. And when you contact us we’ll act quickly, and if something is wrong we’ll make things right. Thanks for taking the time to learn more about us. We hope to have the opportunity to help you get what you need, fast!</p>
</div>
</div>
</main>
</div>
</div>
</div>
</div>
</div>
  )
}
