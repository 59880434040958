import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import './App.css';
import HeaderElement from './components/HeaderElement';
import HeaderBanner from './components/HeaderBanner';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/pages/Home';
import Track from "./components/pages/Track";
import Help from "./components/pages/Help";
import Partners from "./components/pages/Partners";
import Business from "./components/pages/Business";
import Footer from "./components/Footer";
import Individuals from "./components/pages/your-business/Individuals";
import SMEs from "./components/pages/your-business/SMEs";
import Corporates from "./components/pages/your-business/Corporates";
import How_it_works from "./components/pages/How_it_works";
import Terms_and_Conditions from "./components/pages/Terms_and_Conditions";
import About_us from "./components/pages/About_us";
import Login from "./components/pages/Login";
import Home1 from "./components/pages/Home1";
import Test from "./components/pages/Test";
import Quote from "./components/pages/Quote";
import Order_detail from "./components/pages/Order_detail";
import Awblabel from "./components/pages/Awblabel";
import Checkout from "./components/pages/Checkout";
import After_payment from "./components/pages/After_payment";
import Hometest from "./components/pages/Hometest";
import Send from "./components/pages/Send";
import Schedule_pickup from "./components/pages/home_components/track/Schedule_pickup";
import International_courier_services from "./components/pages/International_courier_services";
import Local_courier_same_day_courier from "./components/pages/Local_courier_same_day_courier";
import Register from "./components/pages/Register";
import My_parcels from "./components/pages/my_account/My_parcels";
import Quick_quote from "./components/pages/my_account/Quick_quote";
import Document_quote from "./components/pages/my_account/Document_quote";
import Domestic_quote from "./components/pages/my_account/Domestic_quote";
import Tracking from "./components/pages/my_account/Tracking";
import My_wallet from "./components/pages/my_account/My_wallet";
import After_wallet_payment from "./components/pages/my_account/After_wallet_payment";
import Address_book from "./components/pages/my_account/Address_book";
import Add_address from "./components/pages/my_account/Add_address";
import Settings from "./components/pages/my_account/Settings";
import Client_zone from "./components/pages/Client_zone";
import Dashboard from "./components/pages/dashboard/Dashboard";
import Domestic_orders from "./components/pages/dashboard/Domestic_orders";
import International_orders from "./components/pages/dashboard/International_orders";
import TrackAWB from "./components/pages/TrackAWB";
import Sameday_service from "./components/pages/Sameday_service";
import Prohibited_items from "./components/pages/Prohibited_items";
import Packaging_advice from "./components/pages/Packaging_advice";
import Testimonials from "./components/pages/Testimonials";
import Contact from "./components/pages/Contact";
import Improving_lives from "./components/pages/Improving_lives";
import Medical_services from "./components/pages/Medical_services";
import Friday_service from "./components/pages/Friday_service";
import Become_driver from "./components/pages/Become_driver";
import Privacy_cookies from "./components/pages/Privacy_cookies";
import Sales_enquries from "./components/pages/Sales_enquries";
import Howitworks from "./components/pages/Howitworks";
import Open_wallet from "./components/pages/Open_wallet";
import Shop_usa from "./components/pages/Shop_usa";
import Bulk_upload from "./components/pages/Bulk_upload";
import Sending_multiple from "./components/pages/Sending_multiple";
import Sample from "./components/pages/Sample";
//import ScrollToTop from "./components/pages/ScrollToTop";
import Collect_drop from "./components/pages/Collect_drop";
import Download from "./components/pages/Download";
import TakeAdvantageofcheapflight from "./components/pages/TakeAdvantageofcheapflight";
import FindOutParcel from "./components/pages/FindOutParcel";
import Rearrange_delivery from "./components/pages/Rearrange_delivery";
import Reset_password from "./components/pages/Reset_password";
import Wallet_users from "./components/pages/walletdashboard/Wallet_users";
import Users_wallet from "./components/pages/walletdashboard/Users_wallet";
import Customs_clearance from "./components/pages/Customs_clearance";
import After_payment_Whatsapp from "./components/pages/After_payment_Whatsapp";
import Register_test from "./components/pages/Register_test";
import Smart_send from "./components/pages/Smart_send";
import AwblabelAramex from "./components/pages/AwblabelAramex";
import Sonoco from "./components/pages/Sonoco";
import Orderapp from "./components/pages/Orderapp";
import ScrollToTop from "./components/pages/ScrollToTop ";
import SmartSend from "./components/pages/SmartSend";
import Shopify from "./components/pages/Shopify";
import Woocommerce from "./components/pages/Woocommerce";
import Magento from "./components/pages/Magento";
import Careers from "./components/pages/Careers";
import Articles from "./components/pages/Articles";
import IncredibleLogistics from "./components/pages/articles/IncredibleLogistics";
import GlobalReach from "./components/pages/articles/GlobalReach";
import CompetetivePrices from "./components/pages/articles/CompetetivePrices";
import FleetProductivity from "./components/pages/articles/FleetProductivity";
import ParcelServiceReseller from "./components/pages/articles/ParcelServiceReseller";
import SaveShippingcost from "./components/pages/articles/SaveShippingcost";
import TakeBusinessOnline from "./components/pages/articles/TakeBusinessOnline";
import B2bB2CVendors from "./components/pages/articles/B2bB2CVendors";
import Rating from "./components/pages/Rating";
import Pricing from "./components/pages/Pricing";
import PricingLogin from "./components/pages/PricingLogin";
import Subscription from "./components/pages/Subscription";
import Order_details_Subscription from "./components/pages/Order_details_Subscription";
import My_subscriptions from "./components/pages/my_account/My_subscriptions";
import After_payment_subscription from "./components/pages/After_payment_subscription";
import Wallet_orders from "./components/pages/walletdashboard/Wallet_orders";
import Home_ksa from "./components/pages/Home_ksa";
import Order_detail_ksa from "./components/pages/Order_detail_ksa";
import Quote_ksa from "./components/pages/Quote_ksa";
import Corporate_sme from "./components/pages/Corporate_sme";
import Bulk_orders from "./components/pages/my_account/Bulk_orders";
import Bulk_awblabel from "./components/pages/Bulk_awblabel";
import After_payment_bulkorder from "./components/pages/After_payment_bulkorder";
import BulkAWBAramex from "./components/pages/BulkAWBAramex";
import After_payment_bulkorder_domestic from "./components/pages/After_payment_bulkorder_domestic";
import Staff_discount from "./components/pages/Staff_discount";

function App() {

  return (
    <div className="App">

      <BrowserRouter>
        <ScrollToTop>
        {window.location.pathname !== "/staff-discount-register" ? <HeaderElement /> : null}{" "}
          
          <Routes>
         
            <Route path="/" element={<Home1 />}></Route>
            <Route path="/ksa" element={<Home_ksa />}></Route>
            <Route path="/home" element={<Home1 />}></Route>
            <Route path="/domestic" element={<Home1 />}></Route>
            <Route path="/track" element={<Track />}></Route>
            <Route path="/your-business" element={<Business />}></Route>
            <Route path="/send" element={<Send />}></Route>
            {/* <Route path="/help" element={<Help/>}></Route> */}
            <Route path="/help/*" element={<Help />}></Route>
            <Route path="/become-a-partner/*" element={<Partners />}></Route>
            <Route path="/your-business/individuals/" element={<Individuals />}></Route>
            <Route path="/your-business/smes/" element={<SMEs />}></Route>
            <Route path="/your-business/corporates/" element={<Corporates />}></Route>
            <Route path="/parcels-on-demand" element={<How_it_works />}></Route>
            <Route path="/terms-conditions" element={<Terms_and_Conditions />}></Route>
            <Route path="/privacy-cookies" element={<Privacy_cookies />}></Route>
            <Route path="/about-us" element={<About_us />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/register" element={<Register_test />}></Route>
            {/* <Route path="/test" element={<Test />}></Route> */}
            <Route path="/quotation" element={<Quote />}></Route>
            <Route path="/quotation-ksa" element={<Quote_ksa />}></Route>
            <Route path="/order-detail" element={<Order_detail />}></Route>
            <Route path="/order-detail-ksa" element={<Order_detail_ksa />}></Route>
            <Route path="/awb-label" element={<Awblabel />}></Route>
            <Route path="/checkout" element={<Checkout />}></Route>
            <Route path="/after-payment/*" element={<After_payment />}></Route>
            {/* <Route path="/after-payment" element={<After_payment/>}></Route> */}

            <Route path="/test" element={<Hometest />}></Route>
            <Route path="/track/schedule-your-pick-up-and-delivery-time" element={<Schedule_pickup />}></Route>
            <Route path="/international-courier-services" element={<International_courier_services />}></Route>
            <Route path="/send/local-courier-same-day-courier" element={<Local_courier_same_day_courier />}></Route>
            <Route path="/my-account/my-parcels" element={<My_parcels />}></Route>
            <Route path="/my-account/quick-quote" element={<Quick_quote />}></Route>
            <Route path="/my-account/document-quote" element={<Document_quote />}></Route>
            <Route path="/my-account/domestic-quote" element={<Domestic_quote />}></Route>
            <Route path="/my-account/track" element={<Tracking />}></Route>
            <Route path="/my-account/my-wallet" element={<My_wallet />}></Route>
            <Route path="/my-account/after-wallet-payment" element={<After_wallet_payment />}></Route>
            <Route path="/my-account/address-book" element={<Address_book />}></Route>
            <Route path="/my-account/delivey-address" element={<Add_address />}></Route>
            <Route path="/my-account/settings" element={<Settings />}></Route>
            <Route path="/my-account/my-subscriptions" element={<My_subscriptions />}></Route>
            <Route path="/client-zone" element={<Client_zone />}></Route>
            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route path="/dashboard/domestic-orders" element={<Domestic_orders />}></Route>
            <Route path="/dashboard/international-orders" element={<International_orders />}></Route>
            <Route path="/track/*" element={<TrackAWB />}></Route>
            <Route path="/track/same-day-delivery/" element={<Sameday_service />}></Route>
            <Route path="/prohibited-restricted-items" element={<Prohibited_items />}></Route>
            <Route path="/packaging-advice" element={<Packaging_advice />}></Route>
            <Route path="/testimonials" element={<Testimonials />}></Route>
            <Route path="/contact-time-express" element={<Contact />}></Route>
            <Route path="/improving-lives" element={<Improving_lives />}></Route>
            <Route path="/medical-services" element={<Medical_services />}></Route>
            <Route path="/track/friday-delivery" element={<Friday_service />}></Route>
            <Route path="/track/sunday-delivery" element={<Friday_service />}></Route>
            <Route path="/become-a-driver" element={<Become_driver />}></Route>
            <Route path="/send/*" element={<Home1 />}></Route>
            <Route path="/sales-enquries" element={<Sales_enquries />}></Route>
            <Route path="/how-it-works" element={<Howitworks />}></Route>
            <Route path="/open-a-wallet-account" element={<Open_wallet />}></Route>
            <Route path="/shop-usa-ship-uae/" element={<Shop_usa />}></Route>
            <Route path="/send/our-bulk-upload-tools" element={<Bulk_upload />}></Route>
            <Route path="/send/sending-multiple-parcels-discount-tool" element={<Sending_multiple />}></Route>
            <Route path="/sample" element={<Sample />}></Route>
            <Route path="/collect-or-drop-off-parcel" element={<Collect_drop />}></Route>
            <Route path="/download" element={<Download />}></Route>
            <Route path="/download/*" element={<Download />}></Route>
            <Route path="/track/take-advantage-of-cheap-freight-from-uae/" element={<TakeAdvantageofcheapflight />}></Route>
            <Route path="/track/find-out-where-your-parcel-is/" element={<FindOutParcel />}></Route>
            <Route path="/track/re-arrange-a-delivery/" element={<Rearrange_delivery />}></Route>
            <Route path="/reset-password" element={<Reset_password />}></Route>
            <Route path="/reset-password/*" element={<Reset_password />}></Route>
            <Route path="/wallet-dashboard" element={<Wallet_users />}></Route>
            <Route path="/wallet-orders" element={<Wallet_orders />}></Route>
            <Route path="/wallet/*" element={<Users_wallet />}></Route>
            <Route path="/customs-clearance-services" element={<Customs_clearance />}></Route>
            <Route path="/order-received-online/" element={<After_payment_Whatsapp />}></Route>
            <Route path="/registertest/" element={<Register_test />}></Route>
            <Route path="/smart-send1/" element={<Smart_send />}></Route>
            <Route path="/awb-label-aramex/" element={<AwblabelAramex />}></Route>
            <Route path="/sonoco" element={<Sonoco />}></Route>
            <Route path="/sonoco-thermosafe" element={<Sonoco />}></Route>
            <Route path="/order" element={<Orderapp />}></Route>
            <Route path="/smart-send" element={<SmartSend />}></Route>
            <Route path="/smart-send/shopify" element={<Shopify />}></Route>
            <Route path="/smart-send/woocommerce" element={<Woocommerce />}></Route>
            <Route path="/smart-send/magento" element={<Magento />}></Route>
            <Route path="/careers" element={<Careers />}></Route>
            <Route path="/blogs" element={<Articles />}></Route>
            <Route path="/blogs/incredible-logistics" element={<IncredibleLogistics />}></Route>
            <Route path="/blogs/global-reach" element={<GlobalReach />}></Route>
            <Route path="/blogs/competitive-prices" element={<CompetetivePrices />}></Route>
            <Route path="/blogs/overcoming-dedicated-fleet-productivity" element={<FleetProductivity />}></Route>
            <Route path="/blogs/7-compelling-reasons-why-you-should-use-a-parcel-service-reseller" element={<ParcelServiceReseller />}></Route>
            <Route path="/blogs/how-to-save-costs-in-shipping" element={<SaveShippingcost />}></Route>
            <Route path="/blogs/how-to-take-your-business-online/" element={<TakeBusinessOnline />}></Route>
            <Route path="/blogs/expand-your-brand-into-gcc/" element={<B2bB2CVendors />}></Route>
            <Route path="/rating" element={<Rating />}></Route>
            <Route path="/review" element={<Rating />}></Route>
            <Route path="/pricing" element={<Pricing />}></Route>
            <Route path="/pricing-login" element={<PricingLogin />}></Route>
            <Route path="/subscribe/*" element={<Subscription />}></Route>
            <Route path="/order-detail-subscription" element={<Order_details_Subscription />}></Route>
            <Route path="/after-payment-subscription" element={<After_payment_subscription />}></Route>
            <Route path="/test1" element={<Test />}></Route>
            <Route path="/corporate-sme" element={<Corporate_sme />}></Route>
            <Route path="/my-account/bulk-upload" element={<Bulk_orders/>}></Route>
            <Route path="/bulk-awb-label" element={<Bulk_awblabel/>}></Route>
            <Route path="/after-payment-bulk" element={<After_payment_bulkorder/>}></Route>
            <Route path="/after-payment-bulk-domestic" element={<After_payment_bulkorder_domestic/>}></Route>
            <Route path="/bulk-domestic-awb-label" element={<BulkAWBAramex/>}></Route>
            <Route path="/staff-discount-register" element={<Staff_discount/>}></Route>




          </Routes>
          {window.location.pathname !== "/staff-discount-register" ? <Footer /> : null}{" "}
         
        </ScrollToTop>
      </BrowserRouter>

      {/* <HeaderBanner/> */}

    </div>
  );
}

export default App;


