import axios from 'axios';
import React, { useState ,forwardRef, useRef, useEffect, createRef } from 'react'
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import banner from '../../images/banners/login.jpg'
import logo from '../../images/logo-purple.png'
import { country_code_array } from './functions';
// import TagManager from 'react-gtm-module'
// const tagManagerArgs = {
//   gtmId: 'GTM-NR8WXLS'
// }
// TagManager.initialize(tagManagerArgs)
 

export default function Awblabel() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const INTEGRA_URL = process.env.REACT_APP_INTEGRA_URL;
const display_currency=sessionStorage.getItem('display_currency');
    let newDate = new Date()
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
const today=`${year}/${month}/${date}`
const [parcelcount, setparcelcount] = useState(1)
    const location = useLocation();
    const [order, setorder] = useState('')
    const [parcelcontent, setparcelcontent] = useState('')
    const [countries, setcountries] = useState(country_code_array);
    const [codebar, setcodebar] = useState(1)
    const [orderdata, setorderdata] = useState(false)
    const [downloadlink, setdownloadlink] = useState()
    useEffect(() => {
      if(order==""){

     
        console.log(location.state.awbdetail)
    const API_PATH2=`${INTEGRA_URL}get_order_details.php`;
    axios({
        method: 'post',
        url: `${API_PATH2}`,
        data: location.state.orderno
      })
      .then((res)=>{
    
    if(res.data !=""){
      setorderdata(true);
    
      setorder(JSON.parse(JSON.stringify(res.data)))
      if(res.data.parcel!=""){
     setparcelcontent(res.data.parcel)
      setparcelcount(res.data.parcel.length)
      }
    
      setdownloadlink('https://timexpress.ae/label/pdf-download-awbno.php?awb='+location.state.awbdetail.awbdetails.awbNo)
      Afterdata(res.data.collectiondetails.email,res.data.orderdetails.total_price)
    
    }
  
      })
      .catch((err)=>console.log(err))
    }else{
     
    }
  }, [])



  
 
      const ref = useRef();
      const ref1 = useRef();

function Afterdata(emailid,total_price1){ 
      window.dataLayer = window.dataLayer || [];  
      window.dataLayer.push({
        'event':'purchase_confirmed',
        'order_value':total_price1,
        'order_id':location.state.orderno,
        'email': emailid
       });
  
      
    }
  return (
    <div class="awblabel ">

<Helmet>
        <title>Time Express | Order Confirmation</title>
      </Helmet>
        <section className="your_business_page fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
        <div className="container"><div className="row">
          <div className="col-md-12 col-sm-12 your_business text-center">
            <div className="business_content">
              
              <h1 className='h1-title'>ORDER DETAIL</h1>
            </div>
          </div>
        </div>
        </div>
      </section>

     <style type="text/css" media="print" dangerouslySetInnerHTML={{__html: "\n  @page { size: landscape; }\n   @page { margin: 0; size: A4;}\n   @media print {\n  footer {\n    position: fixed;\n    bottom: 0;\n  }\n\n  .content-block, p {\n    page-break-inside: avoid;\n  }\n\n  html, body {\n    width: 210mm;\n    height: 297mm;\n  }\n}\n" }} />
   
<div className=" parsel_container pages_content" >
<p className="received_text" style={{textAlign: 'left'}}>
Please print the label and attach to your package, our driver will come shortly to collect your parcel. A PDF copy of your label is also available to download from your confirmation email.</p>


{
                location.state.awbdetail.awbdetails?(
                  <p className="received_text" style={{textAlign: 'left'}}><a href={downloadlink}>Download Label</a></p>

                ):null
              }

      <div className="servies-content become_driver last_order thank-you-page">
        {/* <div className="print-btn">
          <input type="button" data_id="print_<?php echo $label; ?>" id="btnPrint<?php echo $label; ?>" defaultValue="Print" />
        </div> */}

<ReactToPrint content={() => ref.current}>
        <PrintContextConsumer>
          {({ handlePrint }) => (
            <button onClick={handlePrint} className="btn btn-small" style={{marginBottom:"2%",marginTop:"2%",background:"#7f3f98",color:"#fff",minWidth:"100px"}}>Print</button>
          )}
        </PrintContextConsumer>
      </ReactToPrint>
   
      
        <div className="table-responsive " ref={ref}>
          
        {  [...Array(parcelcount)].map((e, i) => <>

      
{ order && order.orderdetails !=""?(
        <table id=""    className="table-bordered table-striped table-hover table product_account awbprint" cellPadding={5} cellSpacing={0} border={1} width="100%" style={{fontFamily: 'arial', margin: 'auto'}}>
        <thead>
          <tr>
            <th style={{textAlign:"center",verticalAlign:"middle"}}><img src={logo} alt="logo"/></th>
            <th>PRODUCT<br /><span>{order.orderdetails.parcel_type=='package'?'XPS':'DOX'}</span></th>
            {order.orderdetails.delivery_from == 'AUH' || order.orderdetails.delivery_from == 'DXB' ?(
              <>
<th>ORIGIN  <br /><span>{order.orderdetails.delivery_from} </span></th>
            <th>DESTINATION  <br /><span>{order.orderdetails.delivery_to}</span></th>
</>
            ):
            (
<>
<th>ORIGIN  <br /><span>{countries[`${order.orderdetails.delivery_from}`]} </span></th>
            <th>DESTINATION  <br /><span>{countries[`${order.orderdetails.delivery_to}`]}</span>
            </th>
</>
            )}
            
            <th>WEIGHT <br /><span> {parcelcontent!=""?( parcelcontent[i].weight):order.orderdetails.total_weight}</span></th>
            <th>NO OF PCS <br /><span>{i+1}/ {parcelcount}</span></th>
          
          </tr>
        </thead> 
        <tbody>
           <tr>
            <td colSpan={1}>
            <tr>
                <td>Account: 11000 </td>
            </tr>
            </td>
            <td colSpan={2}>
            <tr>
                <td >Date:{today}</td>
            </tr>
            </td>
            <td colSpan={2} >
            <tr>
             
                <td colSpan={3}>AMT: {order.orderdetails.total_price} {display_currency}</td>
               
            </tr>
            </td>
            <td colSpan={2} >
            <tr>
                <td colSpan={3}> <td>SERVICE: NOR</td></td>
               
            </tr>
            </td>
           
           </tr>
         
      <tr>
        <td colSpan={3}>
              <h3>FROM</h3>
              <address>
                <p>{order.collectiondetails.name}</p>
                <p>{order.collectiondetails.address}</p>
                <p>{order.collectiondetails.town},{order.collectiondetails.zipcode}</p>
                <p>Phone: {order.collectiondetails.phone}</p>
              </address>
            </td>
            <td colSpan={3} align="center">
              {/* <figure><img src={codebar} style={{marginRight: '-17%'}} /></figure> */}
              <p>TYPE OF ORDER: DELIVERY</p>
              <p>PAYMENT TYPE: {order.orderdetails.pay_method}</p>
             
              {
                location.state.awbdetail.awbdetails?(
<img src={`data:image/png;base64,${location.state.awbdetail.awbdetails.Barcode}`}/>
                ):null
              }
             
            </td>
          </tr>
          <tr>
            <td colSpan={3} rowSpan={3}>
              <h3>To</h3>
              <address>
              <p>{order.deliverydetails.name}</p>
                <p>{order.deliverydetails.address}</p>
                <p>{order.deliverydetails.town},{order.deliverydetails.zipcode}</p>
                <p>Phone: {order.deliverydetails.phone}</p>
              </address>
            </td>
            {/* <td colSpan={3}>DO REF# /ORDER REF# <br />32323332</td> */}
            {
                location.state.awbdetail.awbdetails?(
<td colSpan={3}>DO REF# /ORDER REF# <br />/ {location.state.awbdetail.awbdetails.awbNo}</td>
                ):null
              }
            
          </tr>
          <tr>
            <td colSpan={3}>DESCRIPTION: {order.parcel.parcel_content}</td>
          </tr>
          <tr>
            <td colSpan={3} width="50%">VALUE FOR CUSTOMER:  {order.parcel.value}</td>
          </tr> 
          <tr>
            <td colSpan={6} style={{textAlign: 'center'}}>
              <p className="received_text" style={{display: 'contents'}}>I confirm I have read Time Express prohibited and restricted items as well as hereby consent to the
Time Express terms and conditions.</p>
            </td>
          </tr>
        </tbody>
      </table>
      ):null
      }
  
</>

)}
        </div>
        
        </div>
        </div>
        </div>
    

      
  
  )
}
