import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import banner from '../../images/banners/collect.jpg'
import loading from '../../images/spin.gif'
import { country_code_array } from './functions';
import { domestic_destination_array } from './functions';
import { get_phone_code } from './functions';
import '../../Order.css'
import axios from 'axios';
import { Helmet } from 'react-helmet';
import ScrollToTop from './ScrollToTop ';

export default function Order_detail() {

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const INTEGRA_URL = process.env.REACT_APP_INTEGRA_URL;

  const navigate = useNavigate()
  let userid = sessionStorage.getItem('userid')
  const location = useLocation()
  const [countries, setcountries] = useState(country_code_array);
  const [domesticloc, setdomesticloc] = useState(domestic_destination_array);
  const [countryphone, setcountryphone] = useState(get_phone_code);

  const inputs = JSON.parse(sessionStorage.getItem('qouteinput'))

  const [destination, setdestination] = useState(inputs['destination'])
  const [origin, setorigin] = useState(inputs['origin'])

  sessionStorage.setItem('display_currency','AED');
  const [parcel_details, setparcel_details] = useState()
  const [uploadstats, setuploadstats] = useState(1)
  const stateRef = useRef();
  stateRef.current = uploadstats;
  const [collectionDetails, setcollectionDetails] = useState(
    {
      name: '',
      email: '',
      phonecode: countryphone[origin],
      phone: '',
      address: '',
      city: '',
      country: inputs['origin'],
      zip_code: ''
    }
  )


 

  const [deliveryDetails, setdeliveryDetails] = useState(
    {
      name: '',
      email: '',
      phonecode: countryphone[destination],
      phone: '',
      address: '',
      city: '',
      country: inputs['destination'],
      zip_code: ''
    }
  )
  const [parcel_info, setparcel_info] = useState({
    parceldetails: {
      description: [],
      parcel_value: [],
      promocode: [],
      insurance: ['No'],
      comments: [],
      image: []
    }
  })

  const [disable, setdisable] = useState(false)
  useEffect(() => {
    if (inputs['origin'] == 'DXB' || inputs['origin'] == 'AUH') {
      setdestination('AE')
      setorigin('AE')
      setdisable(true)
      setcollectionDetails({ ...collectionDetails, city: domesticloc[inputs['origin']],phonecode:'+971' })
      setdeliveryDetails({ ...deliveryDetails, city: domesticloc[inputs['destination']],phonecode:'+971'  })

    }
  }, [])



  const dimensions = inputs['dimensions']
  const shipmenttype = inputs['shipmenttype']
  const itemcount = dimensions['weight'].length;
console.log(shipmenttype);
  const [countdiv, setcountdiv] = useState(itemcount)
  const [collectionstate, setcollectionstate] = useState(false)
  const [deliverystate, setdeliverystate] = useState(false)
  function changeState() {
    if (collectionstate == false) {
      setcollectionstate(true)
    } else {
      setcollectionstate(false)
    }
  }
  function changeStatedelivery() {
    if (deliverystate == false) {
      setdeliverystate(true)
    } else {
      setdeliverystate(false)
    }
  }


  function updateparcelinfodesc(val, i) {
    const updatedCount = 1;
    const clonedListing = { ...parcel_info.parceldetails };
    const updatedAccepted = [...clonedListing.description]
    updatedAccepted[i] = val;
    setparcel_info({
      ...parcel_info,
      parceldetails: {
        ...clonedListing,
        description: updatedAccepted
      }
    });
  }

  function updateparcelinfovalue(val, i) {
    const updatedCount = 1;
    const clonedListing = { ...parcel_info.parceldetails };
    const updatedAccepted = [...clonedListing.parcel_value]
    updatedAccepted[i] = val;
    setparcel_info({
      ...parcel_info,
      parceldetails: {
        ...clonedListing,
        parcel_value: updatedAccepted
      }
    });
  }

  function updateparcelinfoinsurance(val, i) {
    const updatedCount = 1;
    const clonedListing = { ...parcel_info.parceldetails };
    const updatedAccepted = [...clonedListing.insurance]
    updatedAccepted[i] = val;
    // console.log(parcel_info.parceldetails.parcel_value)
    // if(val=='yes' && parcel_info.parceldetails.parcel_value[i]==[] ){
    //   seterrormsgvalue('Please enter parcel value')
    // }
    
    setparcel_info({
      ...parcel_info,
      parceldetails: {
        ...clonedListing,
        insurance: updatedAccepted
      }
    });
  }

  function updateparcelinfocomments(val, i) {
    const updatedCount = 1;
    const clonedListing = { ...parcel_info.parceldetails };
    const updatedAccepted = [...clonedListing.comments]
    updatedAccepted[i] = val;
    setparcel_info({
      ...parcel_info,
      parceldetails: {
        ...clonedListing,
        comments: updatedAccepted
      }
    });
  }
  function updateparcelinfopromocode(val, i) {
    const updatedCount = 1;
    const clonedListing = { ...parcel_info.parceldetails };
    const updatedAccepted = [...clonedListing.promocode]
    updatedAccepted[i] = val;
    setparcel_info({
      ...parcel_info,
      parceldetails: {
        ...clonedListing,
        promocode: updatedAccepted
      }
    });
  } const [file, setFile] = useState([])
  function updateparcelimage(val, i) {
    const updatedCount = 1;
    const clonedListing = { ...parcel_info.parceldetails };
    const updatedAccepted = [...clonedListing.image]
    updatedAccepted[i] = val;

    setFile(val)
    setparcel_info({
      ...parcel_info,
      parceldetails: {
        ...clonedListing,
        image: updatedAccepted
      }
    });
  }



  const [parceldetails, setparceldetails] = useState('active')
  const [ordersummary, setordersummary] = useState()
  const [finalprice, setfinalprice] = useState()
  const [payment_option, setPaymentoption] = useState('false')
  const [quoteresult, setquoteresult] = useState()
  const [errormsg, seterrormsg] = useState()
  const [insuranceerrormsg, setinsuranceerrormsg] = useState()
  const [emailerrormsg, setemailerrormsg] = useState()
  const [emailerrormsg2, setemailerrormsg2] = useState()
  const [labelerror, setlabelerror] = useState()
  const [nextpage, setnextpage] = useState(0)
  //const API_PATH='http://localhost:8000/quote_order.php';
  const API_PATH = `${SERVER_URL}quote_order.php`;
  function showPaymentoption() {
   setnextpage(1)
    let error = 0; let error2 = 0; let erroremail = 0; let erroremail2 = 0; let errorinsurance=0;
    if (collectionDetails.name == "" || collectionDetails.phone == "" || collectionDetails.email == "" || collectionDetails.address == "" || collectionDetails.city == "") {
      // alert('Please enter the required fields')
      seterrormsg('Please enter the required fields')
      setcollectionstate(true)
      error = 1
    } else {
      seterrormsg()
      error = 0;
      let lastAtPos = collectionDetails.email.lastIndexOf("@");
      let lastDotPos = collectionDetails.email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          collectionDetails.email.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          collectionDetails.email.length - lastDotPos > 2
        )
      ) {
   
        erroremail = 1
        setemailerrormsg('Please enter a valid collection email id')
      } else {
        error = 0;
        erroremail = 0;
        setemailerrormsg()
      }
    }
    if (deliveryDetails.name == "" || deliveryDetails.phone == ""  || deliveryDetails.address == "" || deliveryDetails.city == "") {
      seterrormsg('Please enter the required fields')
      setdeliverystate(true)
      error2 = 1
    } else {
      seterrormsg()
      seterrormsg()
      let lastAtPos = deliveryDetails.email.lastIndexOf("@");
      let lastDotPos = deliveryDetails.email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          deliveryDetails.email.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          deliveryDetails.email.length - lastDotPos > 2
        )
      ) {
       
        //erroremail2 = 1
       // setemailerrormsg2('Please enter a valid delivery email id')
      } else {
        error2 = 0;
        erroremail2 = 0;
        setemailerrormsg2()
      }
    }
    if(parcel_info.parceldetails.insurance.length > parcel_info.parceldetails.parcel_value.length){
      if(parcel_info.parceldetails.insurance[0]=='Yes'){
        setinsuranceerrormsg('Please enter parcel value')
        errorinsurance=1
      }
      
    }else{
      setinsuranceerrormsg('')
      errorinsurance=0
    }

  

    if ((error == 0) && (error2 == 0) && (erroremail == 0) && (erroremail2 == 0) && (errorinsurance== 0)) {
    if(sessionStorage.getItem('indiaquote')&& sessionStorage.getItem('indiaquote')==1){
    
     const API_india=`${SERVER_URL}quote_india.php`;
     axios({
      method: 'post',
      url: `${API_india}`,
      data: {getQuotedata:getQuotedata,parcel_info: parcel_info,collectionDetails: collectionDetails}
    })
      .then((res) => {
       
        //setresultmsg(res.data.sent)
        sessionStorage.setItem('qouteinput', JSON.stringify(getQuotedata))

        sessionStorage.setItem('quotevalues', JSON.stringify(res.data))
        const xx = JSON.parse(JSON.stringify(res.data))
        setquoteresult(xx)
        setfinalprice(xx['rate'])
        //  navigate('/quotation',{state:{qoute_rate:res.data.rate,get_gov_vat:res.data.charge1,charge_rate:res.data.charge_rate,es_surcharge:res.data.es_surcharge,oversize:res.data.oversize,er_charge:res.data.RD_charge,user_discount:res.data.user_discount}})


        setPaymentoption(true)
        setordersummary('active')
        setparceldetails('')
      })
    }
    else{
      axios({
        method: 'post',
        url: `${API_PATH}`,
        data: {getQuotedata:getQuotedata,parcel_info: parcel_info,collectionDetails: collectionDetails}
      })
        .then((res) => {
          
          //setresultmsg(res.data.sent)
          sessionStorage.setItem('qouteinput', JSON.stringify(getQuotedata))

          sessionStorage.setItem('quotevalues', JSON.stringify(res.data))
          const xx = JSON.parse(JSON.stringify(res.data))
          setquoteresult(xx)
          setfinalprice(xx['rate'])
          //  navigate('/quotation',{state:{qoute_rate:res.data.rate,get_gov_vat:res.data.charge1,charge_rate:res.data.charge_rate,es_surcharge:res.data.es_surcharge,oversize:res.data.oversize,er_charge:res.data.RD_charge,user_discount:res.data.user_discount}})


          setPaymentoption(true)
          setordersummary('active')
          setparceldetails('')
        })
      }
      
    }


  }
 

  //const API_Domestic = "http://localhost:8000/quote_domestic_order.php";
  const API_Domestic = `${SERVER_URL}quote_domestic_order.php`;
  function showPaymentoptionDomestic() {
    setnextpage(1)
    let error = 0; let error2 = 0; let erroremail = 0; let erroremail2 = 0;
    if (collectionDetails.name == "" || collectionDetails.phone == "" || collectionDetails.email == "") {
      // alert('Please enter the required fields')
      seterrormsg('Please enter the required fields')
      setcollectionstate(true)
      error = 1
    } else {
      seterrormsg()
      let lastAtPos = collectionDetails.email.lastIndexOf("@");
      let lastDotPos = collectionDetails.email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          collectionDetails.email.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          collectionDetails.email.length - lastDotPos > 2
        )
      ) {
       
        erroremail = 1
        setemailerrormsg('Please enter a valid collection email id')
      } else {
        error = 0;
        erroremail = 0;
        setemailerrormsg()
      }


    }
    if (deliveryDetails.name == "" || deliveryDetails.phone == "") {
      seterrormsg('Please enter the required fields')
      setdeliverystate(true)
      error2 = 1
    } else {
      seterrormsg()
      let lastAtPos = deliveryDetails.email.lastIndexOf("@");
      let lastDotPos = deliveryDetails.email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          deliveryDetails.email.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          deliveryDetails.email.length - lastDotPos > 2
        )
      ) {
       
      //  erroremail2 = 1
      //  setemailerrormsg2('Please enter a valid delivery email id')
      } else {
        error2 = 0;
        erroremail2 = 0;
        setemailerrormsg2()
      }
    }
    if(collectionDetails.phone!='' || deliveryDetails.phone!=''){
    if(inputs['origin'] == 'DXB' || inputs['origin'] == 'AUH'){
      if(collectionDetails.phone.length >10 ||collectionDetails.phone.length< 9 ||deliveryDetails.phone.length>10 || deliveryDetails.phone.length < 9){
    
       seterrormsg('Please enter valid  phone number')
       error=1;
      }
      if(collectionDetails.phone.length==10){
        if(collectionDetails.phone.charAt(0)!=0){
          seterrormsg('Please enter valid  phone number')
          error=1;
        }
      }

      if(deliveryDetails.phone.length==10){
        if(deliveryDetails.phone.charAt(0)!=0){
          seterrormsg('Please enter valid  phone number')
          error=1;
        }
      }
    }
  }

    if ((error == 0) && (error2 == 0) && (erroremail == 0) && (erroremail2 == 0)) {
      axios({
        method: 'post',
        url: `${API_Domestic}`,
        data: {getQuotedata:getQuotedata,parcel_info: parcel_info,collectionDetails: collectionDetails}
      })
        .then((res) => {
          //setresultmsg(res.data.sent)
          sessionStorage.setItem('qouteinput', JSON.stringify(getQuotedata))

          sessionStorage.setItem('quotevalues', JSON.stringify(res.data))
          const xx = JSON.parse(JSON.stringify(res.data))
          setquoteresult(xx)
          setfinalprice(xx['rate'])
          //  navigate('/quotation',{state:{qoute_rate:res.data.rate,get_gov_vat:res.data.charge1,charge_rate:res.data.charge_rate,es_surcharge:res.data.es_surcharge,oversize:res.data.oversize,er_charge:res.data.RD_charge,user_discount:res.data.user_discount}})


          setPaymentoption(true)
          setordersummary('active')
          setparceldetails('')
        })
    }

  }
  const [getQuotedata, setgetQuotedata] = useState(
    inputs
  )

  function updateweight(val, i) {
    const updatedCount = 1;
    const clonedListing = { ...getQuotedata.dimensions };
    const updatedAccepted = [...clonedListing.weight]
    updatedAccepted[i] = val;
    setgetQuotedata({
      ...getQuotedata,
      dimensions: {
        ...clonedListing,
        weight: updatedAccepted
      }
    });

  }
  function updatelength(val, i) {
    const updatedCount = 1;
    const clonedListing = { ...getQuotedata.dimensions };
    const updatedAccepted = [...clonedListing.length]
    updatedAccepted[i] = val;
    setgetQuotedata({
      ...getQuotedata,
      dimensions: {
        ...clonedListing,
        length: updatedAccepted
      }
    });

  }

  function updatewidth(val, i) {
    const updatedCount = 1;
    const clonedListing = { ...getQuotedata.dimensions };
    const updatedAccepted = [...clonedListing.width]
    updatedAccepted[i] = val;
    setgetQuotedata({
      ...getQuotedata,
      dimensions: {
        ...clonedListing,
        width: updatedAccepted
      }
    });

  }

  function updateheight(val, i) {
    const updatedCount = 1;
    const clonedListing = { ...getQuotedata.dimensions };
    const updatedAccepted = [...clonedListing.height]
    updatedAccepted[i] = val;
    setgetQuotedata({
      ...getQuotedata,
      dimensions: {
        ...clonedListing,
        height: updatedAccepted
      }
    });

  }

const [ksa, setksa] = useState(0)
  const [paymentmethod, setpaymentmethod] = useState('Online')
  const [loadingshow, setloadingshow] = useState(false)
  //const API_PATH2='http://localhost:8000/insert_order.php';
 const API_PATH2 = `${SERVER_URL}insert_order.php`;
  const user = sessionStorage.getItem('userid');

  function saveOrder(event) { 
console.log(deliveryDetails)
   if(deliveryDetails.name!='' && deliveryDetails.address!='' && collectionDetails.name!='' && collectionDetails.address!=''){
    setloadingshow(true)
    event.preventDefault();
    axios({
      method: 'post',
      url: `${API_PATH2}`,
      data: {
        collectionDetails: collectionDetails, deliveryDetails: deliveryDetails, getQuotedata: getQuotedata, paymentmethod: paymentmethod,
        quoteresult: quoteresult, parcel_info: parcel_info, user: user,ksa:ksa
      }
    })
      .then((res1) => {
        console.log(res1.data)
        if (file != '') {
         saveOrder2(event, res1.data.parcelid, res1.data.orderid)
         
        }
       
        if ((paymentmethod == 'COC' || paymentmethod == 'COD')) { //cash on collection/ cash on delivery

          if (res1.data.orderid > 0) {
           
          const API_PATH2 = `${INTEGRA_URL}create_awb.php?method=cash`;
           
            axios({
              method: 'post',
              url: `${API_PATH2}`,
              data: res1.data.orderid
            })
              .then((res) => {
              console.log(res.data)
                if (res.data != "") {
                  console.log(res.data)
                  if(res.data.awbdetails==0){
                    setlabelerror(res.data.errormsg)
                  }else{
                 send_label(res1.data.orderid)
                   send_whatsapp(res1.data.orderid)
                 setloadingshow(false)
                    navigate('/awb-label', { state: { orderno: res1.data.orderid, awbdetail: res.data } })
                  }
               }
        
              })
              .catch((err) => console.log(err))
          }

        } else if (paymentmethod == 'PBW') {  //pay by wallet


          const API_PATH2 = `${INTEGRA_URL}create_awb.php?method=pbw`;
          axios({
            method: 'post',
            url: `${API_PATH2}`,
            data: res1.data.orderid
          })
            .then((res) => {
              if (res.data != "") {console.log(res.data)
                send_label(res1.data.orderid)
                send_whatsapp(res1.data.orderid)
                setloadingshow(false)
                navigate('/awb-label', { state: { orderno: res1.data.orderid, awbdetail: res.data } })
              }
              // navigate('/awb-label',{state:{orderno:res1.data}})
            })
            .catch((err) => console.log(err))

        } else { //credit card

          const API_payment = `${SERVER_URL}generate_payment.php`;
          axios({
            method: 'post',
            url: `${API_payment}`,
            data: res1.data.orderid
          })
            .then((res) => {
              setloadingshow(false)
              //navigate(res.data)

           navigate('/checkout', { state: { orderno: res1.data.orderid, payment_page: res.data } })

            })
            .catch((err) => console.log(err));
        }
      
     
      }).catch((err) => console.log(err));
    }
  }


  function saveOrderDomestic(event) { 
    console.log(collectionDetails)
    setloadingshow(true)
    event.preventDefault();
    axios({
      method: 'post',
      url: `${API_PATH2}`,
      data: {
        collectionDetails: collectionDetails, deliveryDetails: deliveryDetails, getQuotedata: getQuotedata, paymentmethod: paymentmethod,
        quoteresult: quoteresult, parcel_info: parcel_info, user: user
      }
    })
      .then((res1) => {
        console.log(res1.data)
        if (file != '') {
          saveOrder2(event, res1.data.parcelid, res1.data.orderid)
        }
        if ((paymentmethod == 'COC' || paymentmethod == 'COD')) { //cash on collection/ cash on delivery

          if (res1.data.orderid > 0) {
            //const API_PATH2 = 'http://localhost:8000/create_awb.php?method=cash';
            //const API_PATH2 = `${INTEGRA_URL}create_awb.php?method=cash`;
            const API_PATH2 = `${INTEGRA_URL}create_awb_2.php?method=cash`;
            axios({
              method: 'post',
              url: `${API_PATH2}`,
              data: res1.data.orderid
            })
              .then((res) => {
              console.log(res.data)
                if (res.data != "") {
                  console.log(res.data)
                  if(res.data.awbdetails==0){
                    setlabelerror(res.data.errormsg)
                  }else{
                  send_label(res1.data.orderid)
                   send_whatsapp(res1.data.orderid)
                 setloadingshow(false)
                navigate('/awb-label-aramex', { state: { orderno: res1.data.orderid, awbdetail: res.data } })
                  }
               }
        
              })
              .catch((err) => console.log(err))
          }

        } else if (paymentmethod == 'PBW') {  //pay by wallet


          const API_PATH2 = `${INTEGRA_URL}create_awb_2.php?method=pbw`;
          axios({
            method: 'post',
            url: `${API_PATH2}`,
            data: res1.data.orderid
          })
            .then((res) => {
              if (res.data != "") {console.log(res.data)
                send_label(res1.data.orderid)
                send_whatsapp(res1.data.orderid)
                setloadingshow(false)
               navigate('/awb-label-aramex', { state: { orderno: res1.data.orderid, awbdetail: res.data } })
              }
              // navigate('/awb-label',{state:{orderno:res1.data}})
            })
            .catch((err) => console.log(err))

        } else { //credit card

          const API_payment = `${SERVER_URL}generate_payment.php`;
          axios({
            method: 'post',
            url: `${API_payment}`,
            data: res1.data.orderid
          })
            .then((res) => {
              setloadingshow(false)
              //navigate(res.data)

              navigate('/checkout', { state: { orderno: res1.data.orderid, payment_page: res.data } })

            })
            .catch((err) => console.log(err));
        }

      }).catch((err) => console.log(err));

  }

  function send_label(orderids) {
    console.log('email funct')
    const API_email = `${INTEGRA_URL}label_email.php`;
    axios({
      method: 'post',
      url: `${API_email}`,
      data: orderids
    })
      .then((res) => {
        console.log(res.data)
        //navigate(res.data)


      })
      .catch((err) => console.log(err));
  }
  function send_whatsapp(orderids) {
    console.log('whatsapp funct')
    const API_whatsapp = `${INTEGRA_URL}send_whatsapp.php`;
    axios({
      method: 'post',
      url: `${API_whatsapp}`,
      data: orderids
    })
      .then((res) => {
        console.log(res.data)
        //navigate(res.data)


      })
      .catch((err) => console.log(err));
  }

 
  function saveOrder2(event, parcels, orderid) {

    event.preventDefault();
    const data = new FormData();
    //data.append("file[]", file);
    const imagecount = parcel_info.parceldetails.image.length
    for (var k = 0; k < imagecount; k++) {

      data.append("file[]", parcel_info.parceldetails.image[k]);
    }
    data.append("orderno", orderid)
    data.append("parcels", parcels)

    let url = `${SERVER_URL}fileupload.php`;

    axios.post(url, data, {
      // receive two parameter endpoint url ,form data
    })
      .then((res) => {
        console.log(res.data)
       if(res.data==0){
        setuploadstats(0)
        alert("invoice file failed to upload")
       }
        // then print response status

      }, error => {
        alert(error);
      }).catch((err) => console.log(err));


  }

  const [wallet, setwallet] = useState()
  const [insuff, setinsuff] = useState(false)
  function alterpaymethod(val) {

    if (val == 'COC' || val == 'COD') {
      if (paymentmethod != val) {
        setpaymentmethod(val)
       console.log(parseFloat(finalprice))
        setfinalprice((parseFloat(finalprice) + 10.00).toFixed(2))
      }

    } else if (val == 'PBW') {
      if (paymentmethod != val) {
        if (paymentmethod == 'COD' || paymentmethod == 'COC') {
          setfinalprice((parseFloat(finalprice) - 10.00).toFixed(2))
        }

        const API_PATH2 = `${SERVER_URL}get_wallet.php`;

        axios({
          method: "post",
          url: `${API_PATH2}`,
          data: userid
        })
          .then((res) => {
console.log(userid)
            setwallet(res.data.wallet)
            if (Number(finalprice) > Number(res.data.wallet)) {
              setinsuff(true)

            }
            setpaymentmethod(val)
          })
          .catch((err) => console.log(err))
      }
    } else {
      if (paymentmethod != val) {
        if (paymentmethod == 'COD' || paymentmethod == 'COC') {
          setfinalprice((parseFloat(finalprice) - 10.00).toFixed(2))
        }
        setpaymentmethod(val)


      }
    }

  }

  const [collectionaddress, setcollectionaddress] = useState()
  const [collectioncount, setcollectioncount] = useState()
  const [deliveryaddress, setdeliveryaddress] = useState()
  const [deliverycount, setdeliverycount] = useState()
  const addresspath = `${SERVER_URL}get_address_order.php`;
  useEffect(() => {
    if (userid && userid > 0) {
      axios({
        method: "post",
        url: `${addresspath}`,
        data: {userid:userid,origin:inputs['origin'],destination:inputs['destination']}
      })
        .then((res) => {
          // console.log(res.data.collection)
          setcollectionaddress(res.data.collection)
          setcollectioncount(res.data.collection.length)
          setdeliveryaddress(res.data.delivery)
          setdeliverycount(res.data.delivery.length)

        })
        .catch((err) => console.log(err))
    }
  }, [])

  function getAddress(val) {
    // console.log(val)

    setcollectionDetails({
      ...collectionDetails,
      name: collectionaddress[val]['name']
      , email: collectionaddress[val]['email'],
      phone: collectionaddress[val]['phone'],
      address: collectionaddress[val]['address'],
      city: collectionaddress[val]['city'],
      zip_code: collectionaddress[val]['zipcode']
    })
  }


  function getAddressdelivery(val) {
    // console.log(val)

    setdeliveryDetails({
      ...deliveryDetails,
      name: deliveryaddress[val]['name']
      , email: deliveryaddress[val]['email'],
      phone: deliveryaddress[val]['phone'],
      address: deliveryaddress[val]['address'],
      city: deliveryaddress[val]['city'],
      zip_code: deliveryaddress[val]['zipcode']
    })
  }

  const [addmsg, setaddmsg] = useState()
  function savecollection() {

    const API_PATH = `${SERVER_URL}save_address.php`;
    axios({
      method: 'post',
      url: `${API_PATH}`,
      data: { user: userid, collectionDetails: collectionDetails, type: 'collection' }
    })
      .then((res) => {
        console.log(res.data)
        if (res.data > 0) {
          setaddmsg('Address saved')
        }


      })
      .catch(err => console.log(err))
  }
  const [deliveryaddmsg, setdeliveryaddmsg] = useState()
  function savedelivery() {
    const API_PATH = `${SERVER_URL}save_address.php`;
    axios({
      method: 'post',
      url: `${API_PATH}`,
      data: { user: userid, collectionDetails: deliveryDetails, type: 'delivery' }
    })
      .then((res) => {
        console.log(res.data)
        if (res.data > 0) {
          setdeliveryaddmsg('Address saved')
        }

      })
      .catch(err => console.log(err))
  }

  const [suggestions, setsuggestions] = useState();
  const [showsuggestion, setshowsuggestion] = useState(false)
  const [showsuggestiondelivery, setshowsuggestiondelivery] = useState(false)
  const [placeids, setplaceids] = useState();
  const [placeidsdelivery, setplaceidsdelivery] = useState();
  function checkSuggestions(val){
if(shipmenttype=='domestic'){
  setcollectionDetails({ ...collectionDetails, address: val}) 
}else{
      setcollectionDetails({ ...collectionDetails, address: val})  
   
     
  const PlaceApi=`${SERVER_URL}placeapi.php`;
    //const PlaceApi='http://localhost:8000/placeapi.php';
    if(val.length >3){
   
    axios({
      method:'post',
      url: `${PlaceApi}`,
      data: { place: val,country: origin}
    }).then((res)=>{
      
      if(res.data.status==1){
       
          setshowsuggestion(true)
        setsuggestions(res.data.suggestions)
        setplaceids(res.data.placeids)
      }
    })
    .catch(err=>console.log(err))
  }else{
      setcollectionDetails({ ...collectionDetails, address: val}) 
   
  }
}
  }

  function setSuggestaddress(k){
 
    setcollectionDetails({ ...collectionDetails, address: k })
    const CityApi=`${SERVER_URL}cityApi.php`;
    //const CityApi='http://localhost:8000/cityApi.php';
    axios({
      method:'post',
      url: `${CityApi}`,
      data: {placeid: placeids[k]}
    }).then((res)=>{
      
      if(res.data.status==1){
       
       setcollectionDetails({ ...collectionDetails,address: k, city: res.data.cityname ,zip_code:res.data.zipcode})
      }
    })
    .catch(err=>console.log(err))
  }


  function checkSuggestionsdelivery(val){

    if(shipmenttype=='domestic'){
      setdeliveryDetails({ ...deliveryDetails, address: val })
    }else{

    setdeliveryDetails({ ...deliveryDetails, address: val })
   const PlaceApi=`${SERVER_URL}placeapi.php`;
  //const PlaceApi='http://localhost:8000/placeapi.php';
  if(val.length >3){
 
  axios({
    method:'post',
    url: `${PlaceApi}`,
    data: { place: val,country: destination}
  }).then((res)=>{
    
    if(res.data.status==1){
     
      setshowsuggestiondelivery(true)
      setsuggestions(res.data.suggestions)
      setplaceidsdelivery(res.data.placeids)
    }
  })
  .catch(err=>console.log(err))
}else{
  setdeliveryDetails({ ...deliveryDetails, address: val })
 
}
}
}

  function setSuggestaddressdelivery(k){
    
  
    setdeliveryDetails({ ...deliveryDetails, address: k })
    const CityApi=`${SERVER_URL}cityApi.php`;
   // const CityApi='http://localhost:8000/cityApi.php';
    axios({
      method:'post',
      url: `${CityApi}`,
      data: {placeid: placeidsdelivery[k]}
    }).then((res)=>{
      
      if(res.data.status==1){
       
        setdeliveryDetails({ ...deliveryDetails,address: k,city: res.data.cityname ,zip_code:res.data.zipcode})
      }
    })
    .catch(err=>console.log(err))
  }
function removesuggest(){
  setTimeout(()=>setshowsuggestion(false),300)

}
function removesuggestdelivery(){
  setTimeout(()=>setshowsuggestiondelivery(false),300)

}



  return (
    <div className="order">
       <Helmet>
        <title>Time Express | Order Detail</title>
      </Helmet>
      <section className="your_business_page fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
        <div className="container"><div className="row">
          <div className="col-md-12 col-sm-12 your_business text-center">
            <div className="business_content">
            <h1 className="mainhead">ORDER DETAIL</h1>

            </div>
          </div>
        </div>
        </div>
      </section>
      <div className="stepwise-row">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12 text-center no-pad"><a href="" className={`${parceldetails}`}><i className="fa fa-pencil" />Parcel Details</a></div>
            <div className="col-md-4 col-sm-4 col-xs-12 text-center no-pad"><a href="" className={`${ordersummary}`}><i className="fa fa-check" /> Order Summary</a></div>
            <div className="col-md-4 col-sm-4 col-xs-12 text-center no-pad"><a href="" className=""><i className="fa fa-credit-card" />Submit Order</a></div>
          </div>
        </div>
      </div>
      <div className="parsel_container pages_content">
        <div className="container">
          {
            userid ? (
              <Link className="orderBackButton" to="/home">Home</Link>
            ) : null
          }

          <div className="row" style={{marginTop:"1%"}}>
            <div className="col-md-12 col-sm-12 col-xs-12 become_driver order-cont order-cont order-cnt-pldl">
              {payment_option == 'false' ? (

                <form method="post" id="parcel_details" name="parcel_details" encType="multipart/form-data">


                  <div className="add_more_parcels">
                    <h3 className="sectiontitle">My Parcel Details</h3>
                    {[...Array(countdiv)].map((e, i) => <>
                      <div className="gray_box box_new" id="">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label for="exampleInputEmail1">Parcel {i + 1} Description </label>
                              <input type="text" className="form-control parcel_content" onChange={(e) => updateparcelinfodesc(e.target.value, i)} name="parcel_content[]" id="parcel_content" />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Value</label>
                              <div className="input-group new-group">
                                <div className="input-group-addon">AED</div>
                                <input type="text" className="form-control my_parcel_detail insure_empty" onChange={(e) => updateparcelinfovalue(e.target.value, i)} id="" name="parcel_cost[]" defaultValue="" />
                             
                              </div>
                            </div>
                          </div>
                          {i == 0 ? (<>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Upload invoice</label>
                              <input type="file" className="form-control my_parcel_detail" multiple onChange={(e) => updateparcelimage(e.target.files[0], i)} id="" name="fileToUpload[]" />
                            </div>
                          </div>
                         
                            <div className="col-md-2" style={{}}>
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Promocode</label>
                                <input type="text" className="form-control" defaultValue="" name="promo_code" id="promo_code" placeholder="Enter promocode" onChange={(e) => updateparcelinfopromocode(e.target.value, i)} />
                              </div>
                            </div>
                            </>
                          ) : null}

                        </div>

                        <div className="row">
                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Weight*</label>
                              <div className="input-group new-group">
                                <select className="form-control" name="weight[]" style={{ margin: 0 }} onChange={(e) => updateweight(e.target.value, i)} >
                                  {inputs.shipmenttype && inputs.typediv == 'package' ? (
                                    <>
                                      <option value="0.5" selected={dimensions['weight'][i] === '0.5'} >0.5</option>
                                      <option value="1" selected={dimensions['weight'][i] === '1'}>1</option>
                                      <option value="1.5" selected={dimensions['weight'][i] === '1.5'}>1.5</option>
                                      <option value="2" selected={dimensions['weight'][i] === '2'}>2</option>
                                      <option value="2.5" selected={dimensions['weight'][i] === '2.5'}>2.5</option>
                                      <option value="3" selected={dimensions['weight'][i] === '3'}>3</option>
                                      <option value="3.5" selected={dimensions['weight'][i] === '3.5'}>3.5</option>
                                      <option value="4" selected={dimensions['weight'][i] === '4'}>4</option>
                                      <option value="4.5" selected={dimensions['weight'][i] === '4.5'} >4.5</option>
                                      <option value="5" selected={dimensions['weight'][i] === '5'}>5</option>
                                      <option value="5.5" selected={dimensions['weight'][i] === '5.5'}>5.5</option>
                                      <option value="6" selected={dimensions['weight'][i] === '6'}>6</option>
                                      <option value="6.5" selected={dimensions['weight'][i] === '6.5'}>6.5</option>
                                      <option value="7" selected={dimensions['weight'][i] === '7'}>7</option>
                                      <option value="7.5" selected={dimensions['weight'][i] === '7.5'}>7.5</option>
                                      <option value="8" selected={dimensions['weight'][i] === '8'}>8</option>
                                      <option value="8.5" selected={dimensions['weight'][i] === '8.5'} >8.5</option>
                                      <option value="9" selected={dimensions['weight'][i] === '9'}>9</option>
                                      <option value="9.5" selected={dimensions['weight'][i] === '9.5'}>9.5</option>

                                      {[...Array(70)].map((x, k) => (
                                        k > 9 ? (
                                          <option value={k} key={k} selected={dimensions['weight'][i] === k}>{k}</option>
                                        ) : null



                                      )
                                      )}
                                    </>




                                  ) : (
                                    <>
                                      <option value="0.5" selected={dimensions['weight'][i] === '0.5'}>0.5</option>
                                      <option value="1" selected={dimensions['weight'][i] === '1'}>1</option>
                                      <option value="1.5" selected={dimensions['weight'][i] === '1.5'}>1.5</option>
                                      <option value="2" selected={dimensions['weight'][i] === '2'}>2</option>
                                      <option value="2.5" selected={dimensions['weight'][i] === '2.5'}>2.5</option>
                                    </>
                                  )
                                  }

                                </select>
                                <div className="input-group-addon">Kg</div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Length</label>
                              <div className="input-group new-group">
                                <input type="text" className="form-control my_parcel_detail" defaultValue={dimensions['length'][i]} name="length[]" onChange={(e) => updatelength(e.target.value, i)} />
                                <div className="input-group-addon">cm</div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-2">
                            <div className="form-group">
                              <label>Width</label>
                              <div className="input-group new-group">
                                <input type="text" className="form-control my_parcel_detail" defaultValue={dimensions['width'][i]} name="width[]" onChange={(e) => updatewidth(e.target.value, i)} />
                                <div className="input-group-addon">cm</div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>Height</label>
                              <div className="input-group new-group">
                                <input type="text" className="form-control my_parcel_detail" defaultValue={dimensions['height'][i]} e="height[]" onChange={(e) => updateheight(e.target.value, i)} />
                                <div className="input-group-addon">cm</div>
                              </div>
                            </div>
                          </div>
                          {shipmenttype!='domestic'?(
 <div className="col-md-3">
 <div className="form-group">
   <label>Want to insure your shipment?</label>
   <select className="form-control insure" id="insure<?php echo $i; ?>" data-id="<?php echo $i; ?>" name="insure[]" onChange={(e) => updateparcelinfoinsurance(e.target.value, i)}>
     <option value="Yes">Yes</option>
     <option value="No" selected>No</option>
   </select>
   <label className="error_insurance<?php echo $i; ?>" style={{ color: 'red', display: 'none' }}>(Please complete parcel value)</label>
   <label className="error_insurance1<?php echo $i; ?>" style={{ color: 'red', display: 'none' }} />
 </div>
</div>
                          ):null}
                         
                          <div className="col-md-5">
                            <div className="form-group">
                              <label htmlFor="exampleFormControlTextarea1">Additional Comments</label>
                              <textarea className="form-control" name="addcomment[]" rows="1" maxLength="400" defaultValue={""} onChange={(e) => updateparcelinfocomments(e.target.value, i)} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                    )}
                  </div>

                  {/* Collection Details */}

                  <h3 className="sectiontitle toggle-btn-collection order-cont collection_details" style={{ width: '100%' }}>
                    <a className id="countCollection" role="button" style={{ position: 'relative', color: '#4a4a4a' }}>Collection Details <span style={{ border: "1px solid", padding: "4px", borderRadius: "5px" }} onClick={changeState}>+</span>
                      <div className="center-con" style={{ right: '-40px' }}>
                        <div className="round">

                        </div>
                      </div>
                    </a>
                  </h3>
                  {collectionstate == true ? (
                    <div className="coll_de">
                      <div className="gray_box box_new">
                        {userid && userid > 0 ? (
                          <>
                            <div className="row" style={{}}>
                              <div className="col-md-12">
                                <div style={{ float: "right", top: '-30px' }}>
                                  <a onClick={savecollection} className="btn  tooltip-op" style={{ borderRadius: '20px', borderColor: '#7f3f98', background: "#7f3f98", color: "#fff" }} id="collection_details" data-id="collection_details">Save <span className="tooltiptext-op"></span> </a>
                                  <p>{addmsg}</p>
                                </div>
                                <div style={{ position: 'absolute', right: 0 }}> <label id="error_col_del" style={{ display: 'none' }} />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-3" style={{ marginTop: "-6px" }}>
                                <select className="form-control" name="save_details_collection" onChange={(e) => getAddress(e.target.value)} id="details_collection">
                                  <option value>Select Save Address</option>

                                  {
                                    [...Array(collectioncount)].map((e, i) => <>
                                      {collectionaddress && collectionaddress[i] != '' ? (

                                        <option key={i} value={i}>{collectionaddress[i]['name']}-{collectionaddress[i]['address']}</option>
                                      ) : null
                                      }
                                    </>
                                    )
                                  }
                                </select>
                              </div>
                              <div className="col-md-2 adrresscheck">  <Link to="/my-account/address-book" style={{ padding: '10px', backgroundColor: '#7f3f98', color: '#fff', borderRadius: '4px', textDecoration: " none" }} className="addresslink">Check address book</Link>
                              </div>
                            </div>
                          </>
                        ) : null
                        }


                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Name<span className="required">*</span></label>
                              <input type="text" className={nextpage==0?'form-control':nextpage==1&&collectionDetails.name.length<1?'error form-control':'form-control'}  value={collectionDetails.name} name="collection_name" onChange={(e) => setcollectionDetails({ ...collectionDetails, name: e.target.value })} id="collection_name" maxLength={50} placeholder="Name" />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Email<span className="required">*</span></label>
                              <input type="email" className={nextpage==0?'form-control':nextpage==1&&collectionDetails.email.length<1?'error form-control':'form-control'} value={collectionDetails.email} name="collection_email" onChange={(e) => setcollectionDetails({ ...collectionDetails, email: e.target.value })} id="collection_email" placeholder="Email" />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Phone<span className="required">*</span></label>
                              <div className="input-group new-group">
                                <div className="input-group-addon" style={{ paddingTop: "6%" }}>{countryphone[`${origin}`]}</div>
                                <input type="text" className={nextpage==0?'form-control':nextpage==1&&collectionDetails.phone.length<1?'error form-control':'form-control'} value={collectionDetails.phone} name="collection_phone" id="collection_phone" onChange={(e) => setcollectionDetails({ ...collectionDetails, phone: e.target.value })} placeholder="Phone" style={{ margin: 0 }} />
                                <input type="hidden" name="collection_phone_code" id="collection_phone_code" defaultValue="" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Address<span className="required">*</span></label>
                              <input type="text" className={nextpage==0?'form-control':nextpage==1&&collectionDetails.address.length<1?'error form-control':'form-control'} value={collectionDetails.address} name="collection_address" onChange={(e)=>checkSuggestions(e.target.value)}  id="collection_address" placeholder="Address" onBlur={removesuggest} />
                              {showsuggestion==true?(<>
                              <div className="suggestions">
                                {
                                  suggestions.map((k, i) => <>
                                  {i>0?(
                                <p key={i} className="suggest" value={k} onClick={() =>setSuggestaddress(k) }  onBlur={removesuggest}>{k}</p>
                                  ):null}
                                  
                                  </>
                                  )
                                }
                                </div>
                              </>
                              ):null}


                            </div>
                          </div>

                          <div className="col-md-8">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1">City/Town<span className="required">*</span></label>
                                  {disable ? (
                                    <input type="text" disabled className="form-control" value={collectionDetails.city} name="collection_city" onChange={(e) => setcollectionDetails({ ...collectionDetails, city: e.target.value })} id="collection_city" placeholder="City/Town" />
                                  ) : (
                                    <input type="text" className={nextpage==0?'form-control':nextpage==1&&collectionDetails.city.length<1?'error form-control':'form-control'} value={collectionDetails.city} name="collection_city" onChange={(e) => setcollectionDetails({ ...collectionDetails, city: e.target.value })} id="collection_city" placeholder="City/Town" />
                                  )}

                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1">Country<span className="required">*</span></label>

                                  <input type="text" className="form-control" name="collection_country" id="collection_country" defaultValue={countries[`${origin}`]} disabled />


                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1">Zipcode</label>
                                  <input type="text" className="form-control cd" value={collectionDetails.zip_code} name="collection_zipcode" onChange={(e) => setcollectionDetails({ ...collectionDetails, zip_code: e.target.value })} id="collection_zipcode" placeholder="Zipcode" />
                                </div>
                              </div>


                            </div>
                          </div>


                        </div>

                      </div>

                    </div>
                  ) : null}

                  <hr className="hr_border" style={{ margin: '20px 0' }} />
                  {/* Delivery Details */}

                  <h3 className="sectiontitle toggle-btn-collection order-cont collection_details" style={{ width: '100%' }}>
                    <a className id="countDelivery" role="button" style={{ position: 'relative', color: '#4a4a4a' }}>Delivery Details <span style={{ border: "1px solid", padding: "4px", borderRadius: "5px" }} onClick={changeStatedelivery}>+</span>
                      <div className="center-con" style={{ right: '-40px' }}>
                        <div className="round">
                          <span />
                          <span />
                          <span />
                          <span />
                        </div>
                      </div>
                    </a>
                  </h3>
                  {deliverystate == true ? (
                    <div className="del_dd " id="collapseExample1">
                      <div className="gray_box box_new">

                        {userid && userid > 0 ? (
                          <>
                            <div className="row" style={{}}>
                              <div className="col-md-12">
                                <div style={{ float: "right", top: '-30px' }}>
                                  <a onClick={savedelivery} className="btn  tooltip-op" style={{ borderRadius: '20px', borderColor: '#7f3f98', background: "#7f3f98", color: "#fff" }} id="collection_details" data-id="collection_details">Save <span className="tooltiptext-op"></span> </a>
                                  <p>{deliveryaddmsg}</p>

                                </div>
                                <div style={{ position: 'absolute', right: 0 }}> <label id="error_col_del" style={{ display: 'none' }} />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-3" style={{ marginTop: "-6px" }}>
                                <select className="form-control" name="save_details_collection" onChange={(e) => getAddressdelivery(e.target.value)} id="details_collection">
                                  <option value>Select Save Address</option>

                                  {
                                    [...Array(deliverycount)].map((e, i) => <>
                                      {deliveryaddress && deliveryaddress[i] != '' ? (

                                        <option key={i} value={i}>{deliveryaddress[i]['name']}-{deliveryaddress[i]['address']}</option>
                                      ) : null
                                      }
                                    </>
                                    )
                                  }
                                </select>
                              </div>
                              <div className="col-md-2 adrresscheck"> <Link to="/my-account/address-book" style={{ padding: '10px', backgroundColor: '#7f3f98', color: '#fff', borderRadius: '4px', textDecoration: " none" }} className="addresslink">Check address book</Link>
                              </div>
                            </div>
                          </>
                        ) : null
                        }
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Name<span className="required">*</span></label>
                              <input type="text" className={nextpage==0?'form-control':nextpage==1&&deliveryDetails.name.length<1?'error form-control':'form-control'} value={deliveryDetails.name} name="delivery_name" id="delivery_name" onChange={(e) => setdeliveryDetails({ ...deliveryDetails, name: e.target.value })} maxLength={50} placeholder="Name" />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Email<span className="required"></span></label>
                              <input type="text" className={nextpage==0?'form-control':nextpage==1&&deliveryDetails.email.length<1?' form-control':'form-control'} value={deliveryDetails.email} name="delivery_email" id="delivery_email" placeholder="Email" onChange={(e) => setdeliveryDetails({ ...deliveryDetails, email: e.target.value })} />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Phone<span className="required">*</span></label>
                              <div className="input-group new-group">
                                <div className="input-group-addon" style={{ paddingTop: "6%" }}>{countryphone[`${destination}`]}</div>
                                <input type="text" className={nextpage==0?'form-control':nextpage==1&&deliveryDetails.phone.length<1?'error form-control':'form-control'} value={deliveryDetails.phone} name="delivery_phone" id="delivery_phone" placeholder="Phone" style={{ margin: 0 }} onChange={(e) => setdeliveryDetails({ ...deliveryDetails, phone: e.target.value })} />
                                <input type="hidden" name="delivery_phone_code" id="delivery_phone_code" defaultValue="<?php echo $delivery_phone_code ?>" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Address<span className="required">*</span></label>
                              <input type="text" className={nextpage==0?'form-control':nextpage==1&&deliveryDetails.address.length<1?'error form-control':'form-control'} value={deliveryDetails.address} name="delivery_address" id="delivery_address" placeholder="Address" onChange={(e)=>checkSuggestionsdelivery(e.target.value)}  onBlur={removesuggestdelivery} />
                              {showsuggestiondelivery==true?(<>
                              <div className="suggestions">
                                {
                                  suggestions.map((k, i) => <>
                                  {i>0?(
                                <p key={i} className="suggest" value={k} onClick={() =>setSuggestaddressdelivery(k) }  onBlur={removesuggestdelivery}>{k}</p>
                                  ):null}
                                  
                                  </>
                                  )
                                }
                                </div>
                              </>
                              ):null}
                            
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1">City/Town<span className="required">*</span></label>
                                  {disable ? (
                                    <input type="text" disabled className="form-control" value={deliveryDetails.city} name="delivery_city" id="delivery_city" placeholder="City/Town" onChange={(e) => setdeliveryDetails({ ...deliveryDetails, city: e.target.value })} />
                                  ) : (
                                    <input type="text" className={nextpage==0?'form-control':nextpage==1&&deliveryDetails.city.length<1?'error form-control':'form-control'} value={deliveryDetails.city} name="delivery_city" id="delivery_city" placeholder="City/Town" onChange={(e) => setdeliveryDetails({ ...deliveryDetails, city: e.target.value })} />
                                  )}

                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1">Country<span className="required">*</span></label>
                                  <input type="text" className="form-control" name="delivery_country" id="delivery_country" defaultValue={countries[`${destination}`]} disabled />

                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1">Zipcode</label>
                                  <input type="text" className="form-control" value={deliveryDetails.zip_code} name="delivery_zipcode" id="delivery_zipcode" placeholder="Zipcode" onChange={(e) => setdeliveryDetails({ ...deliveryDetails, zip_code: e.target.value })} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                      </div></div>

                  ) : null
                  }
                  <hr className="hr_border" style={{ margin: '20px 0' }} />
                  <div className="btn-save rr-btn" style={{ textAlign: 'center' }}>

                    <input type="hidden" defaultValue={1} name="parcel_stage" />
                    <input type="hidden" name="promo_discount" defaultValue id="promo_discount" />
                    {
                      shipmenttype && shipmenttype == 'domestic' ? (
                        <button className="btn_same r_sub" type="button" onClick={showPaymentoptionDomestic} name="parcel_step1" id="parcel_step1" style={{ marginTop: '10px' }}>SUBMIT</button>

                      ) : (<button className="btn_same r_sub" type="button" onClick={showPaymentoption} name="parcel_step1" id="parcel_step1" style={{ marginTop: '10px' }}>SUBMIT</button>
                      )
                    }
                    <p className="error-message" style={{ color: "red" }}>{errormsg}</p>
                    <p className="error-message" style={{ color: "red" }}>{insuranceerrormsg}</p>
                    <p className="error-message" style={{ color: "red" }}>{emailerrormsg}</p>
                    <p className="error-message" style={{ color: "red" }}>{emailerrormsg2}</p>
                   
                  </div>
                </form>
              ) : (
               <>
                <ScrollToTop />
                <div className="servies-content become_driver ">
                  {quoteresult['basic']>0?(
                  <form id="payment_options" className="form-horizontal" method="post" name="payment_options">

                    <ul className="customradio pay-select-box">
                      <li><h2>Select payment option</h2></li>


                      <li>
                        <input id="f-option" type="radio" defaultValue="Online" onClick={() => alterpaymethod('Online')} name="pay_method" className="pay_method my_option" defaultChecked="checked" />
                        <label htmlFor="f-option">Credit card</label>
                        <div className="check" />
                      </li>

                      {origin == 'AE' ? (

                        inputs['origin']=='DXB' || inputs['origin']=='AUH' ?(
                          <></>
                       ): (<li>
                       <input type="radio" defaultValue="COC" name="pay_method" onClick={() => alterpaymethod('COC')} className="pay_method my_option" id="s-option" />
                       <label htmlFor="s-option">Cash on Collection </label>
                       <div className="check"><div className="inside" /></div>
                     </li>)

                      ) : (
                        <li>
                          <input type="radio" defaultValue="COD" name="pay_method" onClick={() => alterpaymethod('COD')} className="pay_method my_option" id="s-option1" />
                          <label htmlFor="s-option">Cash on delivery</label>
                          <div className="check"><div className="inside" /></div>
                        </li>
                      )
                      }

                      {userid > 0 ? (
                        <li>
                          <input type="radio" defaultValue="PBW" name="pay_method" onClick={() => alterpaymethod('PBW')} className="pay_method my_option" id="s-option2" />
                          <label htmlFor="s-option" >Pay By Wallet</label>
                          <div className="check"><div className="inside" /></div>
                        </li>
                      ) : null}


                    </ul>
                    {paymentmethod == 'PBW' ? (
                      <div className="table-responsive" id="my-wallet-balance" style={{ overflow: "hidden", marginTop: "4%" }}>
                        <div className="row">
                          <div className="col-md-3">&nbsp;</div>
                          <div className="col-md-6">
                            <table className="table-bordered table-striped table-hover table" id="price-table">
                              <tbody>
                                <tr>
                                  <td style={{ backgroundColor: '#7f3f98', color: '#fff', fontWeight: 'bold' }}>My Balance</td>
                                  <td style={{ fontWeight: 'bold' }}>{wallet} AED</td>
                                </tr>
                              </tbody>
                            </table>
                            {insuff == true ? (
                              <div id="less_wallet" style={{ textAlign: 'center', border: '1px solid red', marginBottom: '10px' }}>
                                <p style={{ marginBottom: '6px' }}>Insufficient balance</p>
                                <Link to="/my-account/my-wallet" style={{ marginBottom: '6px' }}>Add Wallet</Link>
                              </div>
                            ) : null
                            }


                            {/*?php 									
                                     }
                                   ?*/}
                          </div>
                          <div className="col-md-3">&nbsp;</div>
                        </div>
                      </div>


                    ) : null
                    }



                    <div className="table-responsive other-option">
                      <table className="table-bordered table-striped table-hover table" id="price-table">
                        <thead>
                          <tr>
                            <th style={{textAlign:'center'}}>Description</th>
                            <th style={{textAlign:'center'}}>Total Shipping Price (AED)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Transportation Charge</td>
                            <td className="total-price">{quoteresult['basic']} </td>
                          </tr>
                          {
                            quoteresult['es_surcharge'] > 0 ? (
                              <tr>
                                <td>Emergency Situation Surcharge</td>
                                <td className="total-price">{quoteresult['es_surcharge']} </td>
                              </tr>
                            ) : null
                          }
                          {
                            quoteresult['oversize'] > 0 ? (
                              <tr>
                                <td>Oversize Charge</td>
                                <td className="total-price">{quoteresult['oversize']} </td>
                              </tr>
                            ) : null
                          }
                          
                          
                          {
                            quoteresult['promocode_discount'] > 0 ? (
                              <tr>
                                <td>{quoteresult['promodesc']}</td>
                                <td className="total-price">- {quoteresult['promocode_discount']} </td>
                              </tr>
                            ) : null
                          }

                            {
                            quoteresult['elivatedcharge'] > 0 ? (
                          <tr>
                            <td>Elevated Risk Charge</td>
                            <td className="total-price">{quoteresult['elivatedcharge']}</td>
                          </tr>
                           ) : null
                          }
                            {
                            quoteresult['restricteddest'] > 0 ? (
                          <tr>
                            <td>Restricted Destination Charge</td>
                            <td className="total-price">{quoteresult['restricteddest']}</td>
                          </tr>
                           ) : null
                          }
                            {
                            quoteresult['charge_rate'] > 0 ? (
                          <tr>
                            <td>{quoteresult['charge1']}</td>
                            <td className="total-price">{quoteresult['charge_rate']}</td>
                          </tr>
                           ) : null
                          }
                          {
                            quoteresult['insurance'] > 0 ? (
                              <tr>
                                <td>Insurance Charge</td>
                                <td className="total-price">{quoteresult['insurance']} </td>
                              </tr>
                            ) : null
                          }
                          {quoteresult['user_discount'] > 0 ? (
                            <tr>
                              {/* <td>User Discount D-Rate (20%)</td> */}
                              <td>{quoteresult['user_text']}</td>
                              <td className="total-price">- {quoteresult['user_discount']}</td>
                            </tr>
                          ) : null}
                          {paymentmethod == 'COD' || paymentmethod == 'COC' ? (
                            <tr>
                              <td>{paymentmethod} Charges</td>
                              <td className="total-price">+10.00 </td>
                            </tr>
                          ) : null

                          }

                          <tr>
                            <td>Door to Door Service</td>
                            <td className="total-price"> {finalprice} AED</td>
                          </tr>
                        </tbody>
                      </table>
                      {loadingshow?(
      <p style={{textAlign:"center",marginTop:"2%"}}><img src={loading} style={{width:"5%"}}/></p> 
    ):null}

{shipmenttype=='domestic'?(
<>{
 paymentmethod=='PBW' && insuff == true ?( <input type="button" onClick={e => saveOrderDomestic(e)} defaultValue="Confirm" name="parcel_step3" className="btn btn-success pull-right" id="parcel_step3" disabled/>):
( <input type="button" onClick={e => saveOrderDomestic(e)} defaultValue="Confirm" name="parcel_step3" className="btn btn-success pull-right" id="parcel_step3" />)
                      }</>
):(
<>
{
   paymentmethod=='PBW' && insuff == true ?( <input type="button" onClick={e => saveOrder(e)} defaultValue="Confirm" name="parcel_step3" className="btn btn-success pull-right" id="parcel_step3" disabled/>):
( <input type="button" onClick={e => saveOrder(e)} defaultValue="Confirm" name="parcel_step3" className="btn btn-success pull-right" id="parcel_step3" />)
                      }

</>
)}

                     
                    
           
                      <p className="error-message" style={{ color: "red" }}>{labelerror}</p>
                    </div>



                  </form>
                  ):(<p style={{textAlign:'center',fontSize:'17px',fontWeight:'bold'}}>Sorry. Unable to get the rates. Please try again!</p>)}
                </div>
                </>

              )
              }


              {/* Order Detail Payment Options */}

            </div>


          </div>


        </div>

      </div>



    </div>
  )
}
