import React from 'react'
import { Helmet } from 'react-helmet'
import '../../Custom.css'
import banner from '../../images/banners/collect.jpg'
export default function TakeAdvantageofcheapflight() {
  return (
    <div className="improvelive">
      <Helmet>
        <title>Time Express | Freight Service</title>
      </Helmet>
       <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
               <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                        <h1 className="mainhead">TAKE ADVANTAGE OF CHEAP FREIGHT FROM UAE</h1>
                           
                        </div>
                    </div>
                </div>
                </div>
            </section>
        <div className="pages_content improvelive">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
            <div className="entry-content"><div className="become_driver im_lives">
            <div className="entry-content">
<h1 style={{display: 'none'}}>Take Advantage of cheap freight from UAE</h1>
<h3>Take Advantage of cheap freight from UAE</h3>
<p>Our courier and air freight services make it easier than ever for businesses, individuals and families to move goods to and from Dubai, Abu Dhabi, and anywhere from the UAE in the most affordable way.</p>
<p>We employ a number of features to ensure we are the most convenient and economical courier company operating in UAE.</p>
<p>We can offer you a door to door service, same or next day delivery, real time tracking tools, email and SMS notifications, and most importantly, peace of mind. Your goods are 100 percent safe with us.</p>
<p>Time Express isn’t just for inter-UAE deliveries. Our customers actually find our international courier service rates are cheaper than those at the post office or any other integrator.</p>
<p>
<strong>What we do:</strong>
</p>
<ul style={{marginLeft: '30px', listStyle: 'disc', marginBottom: '18px'}}>
<p>• Express courier in and outside UAE</p>
<p>• Air freight from UAE</p>
<p>• Road transport from UAE and cross border</p>
<p>• Large and small packages</p>
<p>• Residential and commercial addresses</p>
<p>• Door to door courier service</p>
<p>• Same day delivery services</p>
<p>• Next day courier services</p>
<p>• Bullet delivery</p>
<p>• Give you real time tracking information</p>
</ul>
<p>Whether you require our services frequently or just once, we are the most affordable, experienced and reliable couriers in UAE.</p>
<p>
<strong>We have options to suit:</strong>
</p>
<ul style={{marginLeft: '30px', listStyle: 'disc', marginBottom: '18px'}}>
<p>• Businesses that require our cheap couriers on a regular basis</p>
<p>• Businesses that have single, occasional and/or ad hoc needs</p>
<p>• Individuals and families who are sending small items</p>
<p>• Corporates sending large packages</p>
<p>• Any person or business on a budget</p>
<p>• Anyone who wants the best value for money</p>
</ul>
<p>If you’re still doubting how cheap our freight services are, we implore you to use our quick online quote tool. Just type in your pickup and delivery locations, package type(s) and dimensions and you’re good to go. It’s a no obligation feature of the website so you really have nothing to lose.</p>
<p>We look forward to helping you with your courier, freight and shipping delivery needs, no matter how big or small they may be.</p>
</div>
  </div>
  <h1 style={{display: 'none'}}>medical services</h1>
  
  </div>
            </main>
            </div>
            </div>
            </div>
            </div>
    </div>
  )
}
