import React from 'react'
import { Helmet } from 'react-helmet'
import '../../Custom.css'
import banner from '../../images/banners/login.jpg'
export default function Howitworks() {
  return (

    
    <div className="improvelive">
       <Helmet>
        <title>Time Express | How It Works</title>
      </Helmet>
      <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                 <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                         
                            <h1 className="mainhead">HOW IT WORKS?</h1>
                        </div>
                    </div>
                </div>
                </div>
            </section>
        <div className="pages_content improvelive">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
                <div className="sec">
                <div className="entry-content"><div className="become_driver rate-national"><div className="border_text"><p>If you need to send a courier package to a friend, a business, or a loved one and can’t leave your home, you can simply log into www.timexpress.ae and book your shipment online to over 220 destinations, a Time Express courier will come to your door to pick up the shipment from the comfort of your location. Our network uses integrators such as DHL, UPS, and Fedex and your parcel will be delivered using premium service.</p><p>You can also store your personal details as well as your destinations locations to never have to enter those again, register today to store up to 2000 addresses and avail an additional free 10% Off!</p><h4>How it works?</h4><p>For easy reference and guidance please use the below tutorial video link;<br /><a href="https://drive.google.com/file/d/1MuK_lnQ7h1T_elG8v4C4tGC7EoMLrHe3/view">Booking Tutorial Video</a></p></div></div><p /><h1 style={{display: 'none'}}>How it works</h1></div>
                </div>
            </main>
            </div>
            </div>
            </div>
            </div>
    </div>
  )
}
