import React from 'react'
import { Helmet } from 'react-helmet'
import banner from '../../../images/banners/coorporate.jpg'
export default function Corporates() {
  return (
    <div>
      <Helmet>
        <title>Time Express | Corporates</title>
      </Helmet>
           <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
               <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                        <h1 className="mainhead">Corporates</h1>
                            <p>We're here to help</p>
                        </div>
                    </div>
                </div>
                </div>
            </section>
            <div className="pages_content">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
           <div className="entry-content">
           <div className="become_driver">
           <h4>POWERFUL AND ECONOMICAL INTERNATIONAL COURIER SERVICE SOLUTIONS.</h4>
           <h4>CLOUD-BASED TOOLS AND INTEGRATIONS.</h4>
           <h4>SAVE TIME</h4>
           <h4>INCREASE EFFICIENCY</h4>
           <h4>IMPROVE PERFORMANCE</h4>
           <p>Logistics can be complex with so many products and suppliers. Whether you are sending a sample to multiple distributors, looking at importer of records model, analyzing multi-products options to save money on shipping or customs clearance, or simply using a corporate account to many branches Time Express can help you develop and streamline further efficiency in your express supply chain as well as lower your cost for sending international parcels abroad. Time Express offers you the tools and technology to upload multiple AWB at a click of a button, view the status of your deliveries via the web across many branches, and process your orders faster than ever before.</p>
    </div>
    </div>
    </main>
    </div>
    </div>
    </div>
    </div>
    </div>
  )
}
