import React from 'react'
import laptop from '../../images/How_it_works.png'
import label from '../../images/label.jpg'
import banner from '../../images/banners/login.jpg'
import { Helmet } from 'react-helmet'
export default function How_it_works() {
  return (
    <div>
           <Helmet>
        <title>Time Express | Parcels on Demand</title>
      </Helmet>
          <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
             <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                           
                            <h1 className="mainhead">PARCELS ON DEMAND</h1>
                            <p>Print your labels and book in seconds.</p>
                        </div>
                    </div>
                </div>
                </div>
            </section>
            <div className="pages_content">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
           <div className="entry-content">
           <div className="become_driver">
               <div className="row">
                   <div className="col-md-6">
                       <img src={laptop} style={{width:"100%"}}/>
                   </div>
                   <div className="col-md-6" style={{paddingLeft:"6%"}}>
                   <h4>1. Click</h4>
                   <p>Instantly book & get your parcel quote.</p>
           <h4>2. Print</h4>
           <p>Enter “from” and “to” address information along with your shipment details and print labels to place on your package.</p>
           <h4>3. Send</h4>
           <p>Ship internationally to 220 countries. A Time Express courier will come to your door step to collect your parcel.</p>
           <img src={label} style={{width:"25%"}}/>
                   </div>
               </div>
          
    </div>
    </div>
    </main>
    </div>
    </div>
    </div>
    </div>
    </div>
  )
}
