import React from 'react'
import { useNavigate, Link , useParams, NavLink} from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAtom, faPhone, faSignIn, faSquarePhone, faStar, faUser } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import '../Home.css';
// import logo from '../images/logo-purple.png'
import logo from '../images/logotime.png'
const phoneicon = <FontAwesomeIcon icon={faPhone} />
const usericon = <FontAwesomeIcon icon={faSignIn} />
const regicon = <FontAwesomeIcon icon={faUser} />
const whatsappicon = <FontAwesomeIcon icon={faWhatsapp} />

const d = new Date();
const day = d.getDay()

var time = d.getHours()
const Myp = {
  color: "black",
  textAlign: "left",
  marginLeft:"5px"
}
const topheader={
background:"#000000"
}

export default function HeaderElement() {

  let role=sessionStorage.getItem('userid')
  const navigate=useNavigate()
function logout(){
  sessionStorage.removeItem('userid');
  sessionStorage.removeItem('is_corporate');
  sessionStorage.removeItem('batch_id');
  alert("user logged out successfully");
  navigate('/login')
}
  return (
    <>
   
  <div className="top_header" style={topheader}>
    
 
    <div className="container">
   
      <div className="row">
    
        <div className="col-md-6 col-sm-6">
          <ul>
              <li className="phone-cll acx_phone_hide"> 
              <a className="get_country_code_tel" href="tel:600503327" style={{color:"#fff",background:"none"}}>
                 <span className="icon-sp"></span>
       
                  <span className="number-sp" style={{color:"#fff"}}>  {phoneicon} 600 50 3327   </span> 
               </a>
               </li>
               <li> 
                {day && (day!='0' && time <'18')?(
   <a className="get_country_code_wa" href="https://wa.me/971552689069" target="”_blank”" style={{color:"#fff",background:"none"}}>
   {whatsappicon}<span className="get_wa" style={{color:"#fff"}}>+(971) 55 268 9069</span>
     </a>
                ):null}
              
                   </li>
                   <li />
                   </ul>
                   </div>
                   <div className=" col-md-6 col-sm-6">
                   <ul className="member-login">

                   <li className="phone-cll acx_phone_hide" style={{float:"right"}}> 
                   {
                      role?(
                        <a className="get_country_code_tel" onClick={logout} style={{color:"#fff",background:"none",cursor:"pointer"}}>
                        <span className="icon-sp"></span>
                         <span className="" style={{color:"#fff"}}>  {regicon} Logout   </span> 
                        </a>
                      ):<Link className="get_country_code_tel" to="register" style={{color:"#fff",background:"none"}}>
                     
                      <span className="icon-sp"></span>
                       <span className="number-sp" style={{color:"#fff"}}>  {regicon} Register   </span> 
                       </Link>
                   }
             
               </li>
                   <li className="phone-cll acx_phone_hide" style={{float:"right"}}> 
   

{
  role?(
    <Link className="get_country_code_tel" to="my-account/my-parcels" style={{color:"#fff",background:"none"}}>
    <span className="icon-sp"></span>
   <span className="number-sp" style={{color:"#fff"}}>  {usericon} My Account </span> 
  </Link>
  ):(
    <Link className="get_country_code_tel" to="login" style={{color:"#fff",background:"none"}}>
    <span className="icon-sp"></span>
   <span className="number-sp" style={{color:"#fff",background:"none"}}>  {usericon} Login </span> 
  </Link>
  )
}
              
               </li>
                
                    </ul>
                    </div>
                    </div>
                    </div>
                    </div>
 <div>
    
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="container-fluid">
  <Link className="offset-1 navbar-brand headerlogo" to="/"><img src={logo} style={{width:"100%"}}  /></Link>
  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="offset-1 collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav">
        
            <>
           
 <li className="nav-item">
  <NavLink activeclassname="active" exact  to="/">HOME</NavLink>
          {/* <Link className="nav-link active"  aria-current="page" exact  to="/">HOME  </Link> */}
        </li>
        <li className="nav-item">
        <NavLink  exact  to="/track">TRACK</NavLink>
          {/* <Link className="nav-link" exact  to="/track">TRACK</Link> */}
        </li>
       
        <li className="nav-item">
        {/* <NavLink  exact  to="/send">SEND</NavLink> */}
        <NavLink  exact  to="/smart-send">SMART SEND</NavLink>
          {/* <Link className="nav-link" exact  to="/send">SEND</Link> */}
        </li>
        <li className="nav-item">
        <NavLink  exact  to="/your-business">YOUR BUSINESS</NavLink>
          {/* <Link className="nav-link" exact  to="/your-business">YOUR BUSINESS</Link> */}
        </li>
        <li className="nav-item">
        <NavLink  exact  to="/help">HELP</NavLink>
          {/* <Link className="nav-link" exact  to="/help">HELP</Link> */}
        </li>
        <li className="nav-item">
        <NavLink  exact  to="/become-a-partner">PARTNERS</NavLink>
          {/* <Link className="nav-link" to="/become-a-partner">PARTNERS</Link> */}
        </li>
        <li className="nav-item">
        <NavLink  exact  to="/parcels-on-demand">HOW IT WORKS</NavLink>
          {/* <Link className="nav-link" exact  to="/parcels-on-demand">HOW IT WORKS</Link> */}
        </li>
        <li className="nav-item">
        <NavLink  exact  to="/client-zone">CLIENT ZONE</NavLink>
          {/* <Link className="nav-link" exact  to="/client-zone">CLIENT ZONE</Link> */}
        </li>
        {/* <li className="nav-item">
        <NavLink  exact  to="/pricing">PRICING</NavLink>
        </li> */}
        
       
            </>
        
       
       
      </ul>
    </div>
  </div>
</nav>
    </div>
    </>
  )
}
