import axios from 'axios';
import React, { useState ,forwardRef, useRef, useEffect } from 'react'
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { useLocation, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import banner from '../../images/banners/login.jpg'
import loading from '../../images/spin.gif'
import logo from '../../images/logo-purple.png'
import { country_code_array } from './functions';
const ComponentToPrint = forwardRef((props, ref) => {
    return <div ref={ref}>hello 1 world</div>;
  });

 

export default function After_payment() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const INTEGRA_URL = process.env.REACT_APP_INTEGRA_URL;

  const [searchParams, setSearchParams] = useSearchParams();
  const orderno=searchParams.get("ref")
  const [orderid, setorderid] = useState()
  const location = useLocation();
  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  const today=`${year}/${month}/${date}`
  const [codebar, setcodebar] = useState(1)
  const [orderdata, setorderdata] = useState(false)
  const [order, setorder] = useState('')
  const [parcelcount, setparcelcount] = useState(1)
  const [parcelcontent, setparcelcontent] = useState('')
  const [countries, setcountries] = useState(country_code_array);
const [awbdetails, setawbdetails] = useState()
const [paymentsts, setpaymentsts] = useState()
const [paymentmsg, setpaymentmsg] = useState()
const [downloadlink, setdownloadlink] = useState()
 const API_payment=`${INTEGRA_URL}check_payment2.php`; //for aramex
 // const API_payment=`${INTEGRA_URL}check_payment.php`;
  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_payment}`,
      data: orderno
    })
    .then((res)=>{
   console.log(res.data)
     //console.log(JSON.parse(JSON.stringify(res.data.awbdetails)))
      setorderid(res.data.orderid)
      setpaymentsts(res.data.status)
      setpaymentmsg(res.data.message)
      if(res.data.status==1){
        send_label(res.data.orderid)
       send_whatsapp(res.data.orderid)
      }
      setawbdetails(JSON.parse(JSON.stringify(res.data)))

    })
    .catch((err)=>console.log(err))
      }, [])

      useEffect(() => {
        if(orderid>0){
      console.log(orderid)
      const API_PATH2=`${INTEGRA_URL}get_order_details.php`;
      axios({
          method: 'post',
          url: `${API_PATH2}`,
          data: orderid
        })
        .then((res)=>{
      if(res.data !=""){
     console.log(res.data)
        setorderdata(true);
        setorder(JSON.parse(JSON.stringify(res.data)));
        if(res.data.parcel!=""){
          setparcelcontent(res.data.parcel)
           setparcelcount(res.data.parcel.length)
           }
           setdownloadlink('https://timexpress.ae/label/pdf-download-awbno.php?awb='+awbdetails.awbdetails.awbNo)
           Afterdata(res.data.collectiondetails.email,res.data.orderdetails.total_price)
      }
        })
        .catch((err)=>console.log(err))
    
 } }, [orderid])


 function send_label(orderids){
  console.log('email funct')
  const API_email = `${INTEGRA_URL}label_email.php`;
           axios({
             method: 'post',
             url: `${API_email}`,
             data: orderids
           })
             .then((res) => {
               console.log(res.data)
               //navigate(res.data)
  
            
             })
             .catch((err) => console.log(err));
  }

  function send_whatsapp(orderids){
    console.log('whatsapp funct')
    const API_whatsapp = `${SERVER_URL}send_whatsapp.php`;
             axios({
               method: 'post',
               url: `${API_whatsapp}`,
               data: orderids
             })
               .then((res) => {
                 console.log(res.data)
                 //navigate(res.data)
    
              
               })
               .catch((err) => console.log(err));
    }

  const ref = useRef();

  function Afterdata(emailid,total_price1){
    window.dataLayer = window.dataLayer || [];  
    window.dataLayer.push({
      'event':'purchase_confirmed',
      'order_value':total_price1,
      'order_id':location.state.orderno,
      'email': emailid
     });
   
  }

  return (
    <div class="awblabel ">
        <Helmet>
        <title>Time Express | After Payment</title>
      </Helmet>
        <section className="your_business_page fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
        <div className="container"><div className="row">
          <div className="col-md-12 col-sm-12 your_business text-center">
            <div className="business_content">
              <h2 style={{display:'none'}}>ORDER DETAIL</h2>
              <h1 className='h1-title'>ORDER DETAIL</h1>
            </div>
          </div>
        </div>
        </div>
      </section>

      <style type="text/css" media="print" dangerouslySetInnerHTML={{__html: "\n  @page { size: landscape; }\n   @page { margin: 0; size: A4;}\n   @media print {\n  footer {\n    position: fixed;\n    bottom: 0;\n  }\n\n  .content-block, p {\n    page-break-inside: avoid;\n  }\n\n  html, body {\n    width: 210mm;\n    height: 297mm;\n  }\n}\n" }} />


    {orderdata?(
      <></>
    ):(
      <p style={{textAlign:"center",marginTop:"2%"}}><img src={loading} style={{width:"5%"}}/></p> 
    )}
    


{paymentsts && paymentsts==1?(
  <div className=" parsel_container pages_content" >
<p className="received_text" style={{textAlign: 'left'}}>
Please print the label and attach to your package, our driver will come shortly to collect your parcel. A PDF copy of your label is also available to download from your confirmation email.</p>

{awbdetails.AramexAWB && awbdetails.AramexAWB>0?(

<>
{
              awbdetails.Aramex_label?(
                <p className="received_text" style={{textAlign: 'left'}}><a href={awbdetails.Aramex_label} download="Label" target="_blank">Download / Print Label </a></p>

              ):null
            }
</>
  
):(
  <>
{
                awbdetails.awbdetails.awbNo?(
                  <p className="received_text" style={{textAlign: 'left'}}><a href={downloadlink}>Download Label</a></p>

                ):null
              }
              </>
)}


{/* {JSON.stringify(location.state.awbdetail.awbdetails.awbNo)} */}
      <div className="servies-content become_driver last_order thank-you-page">
        {/* <div className="print-btn">
          <input type="button" data_id="print_<?php echo $label; ?>" id="btnPrint<?php echo $label; ?>" defaultValue="Print" />
        </div> */}
       
      {awbdetails.AramexAWB && awbdetails.AramexAWB>0?(
        <div className="table-responsive ">
      <embed src={`${awbdetails.Aramex_label}#toolbar=0`} style={{width: "100%",height: "600px",border:"none"}}></embed>
       </div>
      ):(
        <>
         <ReactToPrint content={() => ref.current}>
        <PrintContextConsumer>
          {({ handlePrint }) => (
            <button onClick={handlePrint} className="btn btn-small" style={{marginBottom:"2%",marginTop:"2%",background:"#7f3f98",color:"#fff",minWidth:"100px"}}>Print</button>
          )}
        </PrintContextConsumer>
      </ReactToPrint>
<div className="table-responsive " ref={ref}>
          
          {  [...Array(parcelcount)].map((e, i) => <>
  
            
        
  { order && order.orderdetails !=""?(
          <table id=""   className="table-bordered table-striped table-hover table product_account awbprint" cellPadding={5} cellSpacing={0} border={1} width="100%" style={{fontFamily: 'arial', margin: 'auto'}}>
          <thead>
            <tr>
              <th style={{textAlign:"center",verticalAlign:"middle"}}><img src={logo} alt="logo"/></th>
              <th>PRODUCT<br /><span>{order.orderdetails.parcel_type=='package'?'XPS':'DOX'}</span></th>
              <th>ORIGIN  <br /><span>{countries[`${order.orderdetails.delivery_from}`]} </span></th>
              <th>DESTINATION  <br /><span>{countries[`${order.orderdetails.delivery_to}`]}</span>
              </th><th>WEIGHT <br /><span> {parcelcontent!=""?( parcelcontent[i].weight):order.orderdetails.total_weight}</span></th>
              <th>NO OF PCS <br /><span>{i+1}/ {parcelcount}</span></th>
            
            </tr>
          </thead> 
          <tbody>
             <tr>
              <td colSpan={1}>
              <tr>
                  <td>Account: 11000 </td>
              </tr>
              </td>
              <td colSpan={2}>
              <tr>
                  <td >Date:{today}</td>
              </tr>
              </td>
              <td colSpan={2} >
              <tr >
                  <td colSpan={3}>AMT: {order.orderdetails.total_price} AED</td>
                 
              </tr>
              </td>
              <td colSpan={2} >
              <tr >
                  <td colSpan={3}> <td>SERVICE: NOR</td></td>
                 
              </tr>
              </td>
             
             </tr>
           
        <tr>
          <td colSpan={3}>
                <h3>FROM</h3>
                <address>
                  <p>{order.collectiondetails.name}</p>
                  <p>{order.collectiondetails.address}</p>
                  <p>{order.collectiondetails.town},{order.collectiondetails.zipcode}</p>
                  <p>Phone: {order.collectiondetails.phone}</p>
                </address>
              </td>
              <td colSpan={3} align="center">
                {/* <figure><img src={codebar} style={{marginRight: '-17%'}} /></figure> */}
                <p>TYPE OF ORDER: DELIVERY</p>
                <p>PAYMENT TYPE: {order.orderdetails.pay_method}</p>
               
                {
                awbdetails.awbdetails.Barcode?(
<img src={`data:image/png;base64,${awbdetails.awbdetails.Barcode}`}/>
                ):null
              }
               
              </td>
            </tr>
            <tr>
              <td colSpan={3} rowSpan={3}>
                <h3>To</h3>
                <address>
                <p>{order.deliverydetails.name}</p>
                  <p>{order.deliverydetails.address}</p>
                  <p>{order.deliverydetails.town},{order.deliverydetails.zipcode}</p>
                  <p>Phone: {order.deliverydetails.phone}</p>
                </address>
              </td>
              {/* <td colSpan={3}>DO REF# /ORDER REF# <br />32323332</td> */}
              {
               awbdetails.awbdetails.awbNo?(
<td colSpan={3}>DO REF# /ORDER REF# <br />/ {awbdetails.awbdetails.awbNo}</td>
                ):null
              }
              
            </tr>
            <tr>
              <td colSpan={3}>DESCRIPTION: {order.parcel.parcel_content}</td>
            </tr>
            <tr>
              <td colSpan={3} width="50%">VALUE FOR CUSTOMER:  {order.parcel.value}</td>
            </tr> 
            <tr>
              <td colSpan={6} style={{textAlign: 'center'}}>
                <p className="received_text" style={{display: 'contents'}}>I confirm I have read Time Express prohibited and restricted items as well as hereby consent to the
  Time Express terms and conditions.</p>
              </td>
            </tr>
          </tbody>
        </table>
        ):null
        }
  
  </>
  )}
          </div>
          </>
      )}
      

      
        
        </div>
        </div>
):(<p style={{textAlign:"center",marginTop:"2%"}}>{paymentmsg}</p>)
}

       
        </div>
    

      
  
  )

}
