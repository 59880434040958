import React from 'react'
import { Helmet } from 'react-helmet'
import banner from '../../images/banners/sameday.jpg'
export default function Sameday_service() {
  return (
    <div>
      <Helmet>
        <title>Time Express | Same Day Delivery</title>
      </Helmet>
       <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
              <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                        <h1 className="mainhead">SAME DAY DELIVERY</h1>
                            <p>We're here to help</p>
                        </div>
                    </div>
                </div>
                </div>
            </section>
        <div className="pages_content">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
           <div className="entry-content">
           <div className="become_driver">
            <h4>Do You Offer a Same Day Courier Collection Service?</h4>
            <p>We certainly do, but you’ve got to be snappy about it!</p>

<p>Are you an E-commerce company looking to deliver an Online orders on the same day? Or simply a business or consumer looking to deliver an urgent parcel same day?</p>

<p>One of the best services we offer for this Time DIRECT for a same day collection and domestic delivery 365 days of the year and 24 hours a day. We offer full tracking and you can select a time that they will collect the items, plus you can watch the courier via the tracking portal until the item is delivered to your destination. You can also choose a vehicle size which is perfect for your item. There is a minimum quantity required for same day deliveries, and we provide you with internal tools to speed the process, please contact our customer service team to let you know more about how this superior reliable service works.</p>

<p>If you should have sent a parcel yesterday, or you’ve had a call from a customer and they need some goods or paperwork sent urgently then we’re here to save your bacon with a same day collection service.</p>

<p>All you need to do is place your order before 11:30 am and we’ll be able to get it collected and delivered the same day via Time DIRECT, for afternoon orders we will be delivered at the first opportunity in the morning.</p>

<p>If you would like collection and delivery on the same day, then we have a service for you.</p>
<h4>What About A Specific Time Slot?</h4>
<p>To get our prices down as low as they are that means there have to be some sacrifices. To ensure your parcel is delivered to your customer on time, we need to make sure we know where to deliver it, and that you are at your selected business or home location at the time of collection. We offer you specific hour slots to let us know when to collect, and we require your Makani location identification in order to save time in making sure we fulfill our commitment to you. Another alternative would be to book a Time Express drop off service, or Time Pick Up meaning you can drop your parcel off at one of our access points at any time convenient for yourself.</p>
            
            <h4>Same Day Courier Delivery Services</h4>
            <p>Are you promoting your online E-Commerce business in the UAE? Want a same day delivery quote? Customers want your products yesterday and do want to wait too long for their delivery! Send your e-commerce parcel delivery on the same day in UAE. Try our same day couriers delivery services in UAE and worldwide and delight your customers!</p>
            <p>Ready to get started? Email us on info@timexpress.ae!</p>
             </div>


            </div>
            </main>
            </div>
            </div>
            </div>
            </div>
    </div>
  )
}
