import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react'
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash,faListAlt,faBook } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal';
import '../../../My_Account.css'
import { country_code_array } from '../functions';
import Sidebar from './Sidebar';
import logo from '../../../images/logo-purple.png'
import { Helmet } from 'react-helmet';


export default function My_parcels() {
  const INTEGRA_URL = process.env.REACT_APP_INTEGRA_URL;
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const trashicn = <FontAwesomeIcon icon={faTrash} />
  const receipticn = <FontAwesomeIcon icon={faListAlt} />
  const invoiceicn = <FontAwesomeIcon icon={faBook} />
  const [parceldetailscount, setparceldetailscount] = useState(0)
  const [parceldetailscontent, setparceldetailscontent] = useState('')
  const [updatedon, setupdatedon] = useState('')
  const [countries, setcountries] = useState(country_code_array);
  const ref = useRef();

 
 const [current, setcurrent] = useState(5)
 const [page, setpage] = useState(0)
  const userid=sessionStorage.getItem('userid')
  const [showModal, setShowModal] = useState(false);
const [order, setorder] = useState()
  const [modalIsOpen,setModalIsOpen] = useState(false);
  const [modalIsOpen2,setModalIsOpen2] = useState(false);


  const API_PATH=`${SERVER_URL}get_parcels.php`;
  //const API_PATH=`http://localhost:8000/get_parcels.php`;
  const [parcelcount, setparcelcount] = useState(0)
  const [parcelcount2, setparcelcount2] = useState(0)
  const [parcelcontent, setparcelcontent] = useState('')
  useEffect(()=>{
  axios({
  
    method:"post",
    url:`${API_PATH}`,
    data:{userid:userid,current:""}
  })
  .then((res)=>{
  
    if(res.data.parcel!=""){
  
      setparcelcontent(res.data.parcel)
       setparcelcount(res.data.parcel.length)
       setparcelcount2(res.data.totparcel.length)
       }
   
  })
  .catch((err)=>console.log(err))
  },[])



const API_PATH1=`${SERVER_URL}get_parcel_details.php`;
//const API_PATH1=`http://localhost:8000/get_parcel_details.php`;
    const setModalIsOpenToTrue =(val)=>{
      axios({
        method:'post',
        url:`${API_PATH1}`,
        data:val
      })
      .then((res)=>{
        
        
        setorder(JSON.parse(JSON.stringify(res.data)))
        if(res.data.parceldetails){
        setparceldetailscontent(res.data.parceldetails)
        setparceldetailscount(res.data.parceldetails.length)
        setModalIsOpen(true)
        setupdatedon(res.data.orderdetails.updated_on)
       
        // let updateddate=JSON.parse(JSON.stringify(res.data.orderdetails.updated_on))

        // const date = new Date(updateddate);

        // //extract the parts of the date
        // const day = date.getDate();
        // const month = date.getMonth() + 1;
        // const year = date.getFullYear(); 
        // setupdatedon(`${year}/${month}/${day}`)
        }
       
      })
      .catch((err)=>console.log(err))
       
    }

    const setModalIsOpenToFalse =()=>{
        setModalIsOpen(false)
    }
 


    const setModalIsOpenToTrue2 =(val)=>{
      axios({
        method:'post',
        url:`${API_PATH1}`,
        data:val
      })
      .then((res)=>{
       
        
        setorder(JSON.parse(JSON.stringify(res.data)))
        if(res.data.parceldetails){
        setparceldetailscontent(res.data.parceldetails)
        setparceldetailscount(res.data.parceldetails.length)
        setModalIsOpen2(true)

        setupdatedon(res.data.orderdetails.updated_on)
        // let updateddate=JSON.parse(JSON.stringify(res.data.orderdetails.updated_on))

        // const date = new Date(updateddate);

        // //extract the parts of the date
        // const day = date.getDate();
        // const month = date.getMonth() + 1;
        // const year = date.getFullYear(); 
        // setupdatedon(`${year}/${month}/${day}`)
        }
       
      })
      .catch((err)=>console.log(err))
       
    }

    const setModalIsOpenToFalse2 =()=>{
      setModalIsOpen2(false)
  }



function nextpage(val1){

    axios({

      method:"post",
      url:`${API_PATH}`,
      data:{userid:userid,current:val1}
    })
    .then((res)=>{
      
      if(res.data.parcel!=""){
        if(val1>page){
          setcurrent(current+5)
        }
        if(val1<page){
          setcurrent(current-5)
        }
    setpage(val1)
        setparcelcontent(res.data.parcel)
         setparcelcount(res.data.parcel.length)
         }
     
    })
    .catch((err)=>console.log(err))
 
  
}

// function getdays(val){

// let today = new Date();
//                 let dd = String(today.getDate()).padStart(2, '0');
//                 let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
//                 let yyyy = today.getFullYear();
                
//                 today =  yyyy+'-' +mm + '-'+ dd
//                 today=new Date(today);
//                 let date2 =  new Date(val).toISOString().split('T')[0];
//                 date2=new Date(date2);
               
//                let Difference_In_Time = today.getTime()-date2.getTime();
//                 let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
//                 return Difference_In_Days;
// }
const [orderfordel, setorderfordel] = useState()
function OrderDelete(val){
  setorderfordel(val)
  window.confirm('Are you sure you wish to delete this item?') ? deleteorder(val) : deleteorder(0)

}

function deleteorder(val){
  const DeletApi=`${SERVER_URL}delete_parcels.php`;
  if(val>0){
   
    axios({
      method:"post",
      url:`${DeletApi}`,
      data:val
    })
    .then((res)=>{
    
      window.location.reload();
    }).catch((err)=>console.log(err))
  }
  

}

// const navigate=useNavigate()
function getTracking(val){

  window.open('/track/'+val, '_blank', 'noopener,noreferrer');
}

const API_PATH2 = `${INTEGRA_URL}tracking.php`;
const [lateststs, setlateststs] = useState()
function getstatus(val){
 
  axios({
    method: 'post',
    url: `${API_PATH2}`,
    data: val
})
    .then((res) => {

        if (res.data.description = "Success"){
            let finalsts=res.data.trackList[0]
            setlateststs(finalsts.Status)
           //setlateststs(finalsts.Status)
            //return res.data.trackList[0].Status
        }else{
          return 'no update'
        }

    })
    .catch(error => console.log(error.message));
}


  return (
    <div className="my_parcels">
<Helmet>
        <title>Time Express | My Parcels</title>
      </Helmet>

<Modal isOpen={modalIsOpen} ariaHideApp={false}>
    <button onClick={setModalIsOpenToFalse} style={{float:"right"}}>x</button>
    <>
    
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className=" " id="mypage2">
                  <div className="table-responsive ">							
                    <div className="servies-content become_driver last_order thank-you-page">

                    <ReactToPrint content={() => ref.current}>
<PrintContextConsumer>
  {({ handlePrint }) => (
    <button onClick={handlePrint} className="btn btn-small" style={{marginBottom:"14px",background:"#7f3f98",color:"#fff"}}>Print</button>
  )}
</PrintContextConsumer>
</ReactToPrint>
                      <div className="table-responsive ">							
                      {order && order!=""?(



                        <table  ref={ref} id="print_<?php echo xxh; ?>" className="table-bordered table-striped table-hover table product_account" cellPadding={5} cellSpacing={0} border={1} width="100%" style={{fontFamily: 'arial', margin: 'auto'}}>
                          
                       
                          <thead>
                            <tr><th colSpan={10}>
                                <h3 style={{textAlign:"center"}}>Receipt</h3></th>
                            </tr>
                            <tr><th colSpan={5} style={{textAlign:"center"}}><img src={logo} alt="logo" style={{marginBottom:"4%"}} />
                              </th>
                              <th colSpan={5}>Receipt No : {order.orderdetails.id}<br />
                                Date: {updatedon}<br />
                                Awb No: {order.invoice.awbno}<br />
                              </th>
                            </tr>
                            <tr><th colSpan={10} style={{textAlign:"center"}}>Shipment Information</th></tr>
                            <tr>
                              <th colSpan={2}>Reference </th>
                              <th colSpan={2}>Origin </th>
                              <th colSpan={2}>Destination </th>
                              <th colSpan={2}>Pcs</th>
                              <th colSpan={2}>Weight(Kg)</th>
                            </tr>
                          </thead>
                            <tbody>
                            <tr>
                              <td colSpan={2}>Order- {order.orderdetails.id}</td>
                              {order.orderdetails.delivery_from=='DXB' || order.orderdetails.delivery_from=='AUH'|| order.orderdetails.delivery_from.length >2 ?(
 <><td colSpan={2}>{order.orderdetails.delivery_from} </td>
 <td colSpan={2}>{order.orderdetails.delivery_to} </td></>
                              ):(
                                <>
                                <td colSpan={2}>{countries[`${order.orderdetails.delivery_from}`]} </td>
                                <td colSpan={2}>{countries[`${order.orderdetails.delivery_to}`]}</td>
                                </>
                              )
}
                            
                              <td colSpan={2}>{parceldetailscount}</td>
                              <td colSpan={2}>{order.orderdetails.total_weight}</td>
                            </tr>
                            <tr>
                              <td colSpan={5}><p>Shipper : {order.collection.name}<br />
                                  Address: {order.collection.address}<br />
                                  City: {order.collection.town}<br />
                                  Phone: {order.collection.phone}<br />
                                </p></td>
                              <td colSpan={5}><p>Consignee : {order.delivery.name}<br />
                                  Address: {order.delivery.address}<br />
                                  City: {order.delivery.town}<br />
                                  Phone: {order.delivery.phone}<br />
                                 
                                </p></td>
                            </tr>
                            <tr>
                              <td colSpan={5}>
                                <p style={{float: 'right', fontSize: '17px !important'}}><b>Invoice Amount:<b /></b></p><b><b>
                                  </b></b></td>
                              <td colSpan={5}>
                                <p style={{float: 'right', fontSize: '17px !important'}}><b>{order.invoice.invoice_amount} AED</b></p>
                              </td>
                            </tr>
                          </tbody>
                          
                          
                        </table>	
                        ):null
                      }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
        
        <div className="modal-footer">
          <button type="button" className="btn btn-default" onClick={setModalIsOpenToFalse}>Close</button>
        </div>
      </div>
         
            </>
</Modal>

{/* commercial incoice */}

<Modal isOpen={modalIsOpen2} ariaHideApp={false}>
    <button onClick={setModalIsOpenToFalse2} style={{float:"right"}}>x</button>
    <>
    
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className=" " id="mypage2">
                  <div className="table-responsive ">							
                    <div className="servies-content become_driver last_order thank-you-page">

                    <ReactToPrint content={() => ref.current}>
<PrintContextConsumer>
  {({ handlePrint }) => (
    <button onClick={handlePrint} className="btn btn-small" style={{marginBottom:"14px",background:"#7f3f98",color:"#fff"}}>Print</button>
  )}
</PrintContextConsumer>
</ReactToPrint>
                      <div ref={ref} className="table-responsive " style={{paddingLeft:"3%",paddingRight:"3%"}}>							
                      {order && order!=""?(<>
                        <div className="col-md-12">
        <h2 style={{textAlign: 'center'}}>Commercial Invoice</h2>
        <p><b>AWB No:</b> {order.invoice.awbno} &nbsp;<b>Invoice Date:</b> {updatedon} &nbsp;<b>Invoice No:{order.invoice.awbno}</b>
        </p>
      </div>
                        <div className="row" width="100%">
        <div className="col-md-12">
          <table style={{border: 'none',width:"100%"}}>
            <tbody>
              <tr>
                <td colSpan={6} style={{border: 'none'}}><b>SHIP FROM:</b>  <br />
                {order.collection.name}<br />
                                  Address: {order.collection.address}<br />
                                  City: {order.collection.town}<br />
                                  Phone: {order.collection.phone}
                </td>
                <td style={{border: 'none'}}>&nbsp;&nbsp;</td>
                <td style={{border: 'none'}}>&nbsp;&nbsp;</td>
                <td colSpan={6} style={{border: 'none'}}><b>SHIP TO: </b> <br />
               Consignee : {order.delivery.name}<br />
                                  Address: {order.delivery.address}<br />
                                  City: {order.delivery.town}<br />
                                  Phone: {order.delivery.phone}<br />
                </td>
              </tr>
            </tbody>
          </table>

          <table  id="print_<?php echo xxh; ?>" className="table-bordered table-striped table-hover table product_account" cellPadding={5} cellSpacing={0} border={1} width="100%" style={{fontFamily: 'arial', margin: 'auto',marginTop:"3%"}}>
                          
                       
                          <thead>
                            <tr>
                              <th colSpan={2}>Reference</th>
                              <th colSpan={2}>Origin </th>
                              <th colSpan={2}>Destination </th>
                              <th colSpan={2}>Pcs</th>
                              <th colSpan={2}>Weight(Kg)</th>
                            </tr>
                          </thead>
                            <tbody>
                            <tr>
                              <td colSpan={2}>Order- {order.orderdetails.id}</td>
                              {order.orderdetails.delivery_from=='DXB' || order.orderdetails.delivery_from=='AUH' || order.orderdetails.delivery_from.length>2 ?(
 <><td colSpan={2}>{order.orderdetails.delivery_from} </td>
 <td colSpan={2}>{order.orderdetails.delivery_to} </td></>
                              ):(
                                <>
                                <td colSpan={2}>{countries[`${order.orderdetails.delivery_from}`]} </td>
                                <td colSpan={2}>{countries[`${order.orderdetails.delivery_to}`]}</td>
                                </>
                              )
}
                             
                             
                              <td colSpan={2}>{parceldetailscount}</td>
                              <td colSpan={2}>{order.orderdetails.total_weight}</td>
                            </tr>
                           
                          
                          </tbody>
                          
                          
                        </table>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <table style={{border: 'none', marginTop: '3%'}}>
            <tbody>
              <tr>
                <td colSpan={6} style={{border: 'none'}}>Total Goods Value: {order.invoice.invoice_amount} AED<br />
                  Total Invoice Amount: {order.invoice.invoice_amount} AED<br />
                  Currency Code: AED<br />
                  Total Net Weight: {order.invoice.tot_weight}<br />
                  Total Gross Weight:{order.invoice.tot_weight}kg
                </td>
                <td style={{border: 'none'}}>&nbsp;&nbsp;</td>
                <td style={{border: 'none'}}>&nbsp;&nbsp;</td>
                <td style={{border: 'none'}}>&nbsp;&nbsp;</td>
                <td colSpan={6} style={{border: 'none'}}>Total line items: {parceldetailscount}<br />
                  Duty / tax billing service:<br />
                  Carrier: DHL<br />
                  Ultimate Consignee: {order.delivery.name}<br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="row" style={{marginTop: '4%'}}>
          <div className="col-md-12">
            <p>I/We hereby certify that the information contained in the invoice is true and correct and that the contents of this shipment are as stated above.</p>
          </div>
        </div>
        <div className="row" style={{marginTop: '4%'}}>
          <div className="col-md-12">
            <table style={{border: 'none'}}>
              <tbody>
                <tr>
                  <td style={{border: 'none'}}>
                    <b>Name:</b><br />
                    <b>Position:</b><br />
                    <b>Date of Signature:</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

	</>
                        ):null
                      }
                      </div>
                     
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
        
        <div className="modal-footer">
          <button type="button" className="btn btn-default" onClick={setModalIsOpenToFalse2}>Close</button>
        </div>
      </div>
         
            </>
</Modal>
      <div className="container myparctab" >

     
    <div className="container-fluid">
      <div className="row">    
    
      <Sidebar/>
     
        
        {/* /#admin-sidebar */}
        <div id="admin-main-control" className="col-md-9 p-x-3 p-y-1">
          <div className="content-title m-x-auto">
           <h3>My Parcels </h3>
          </div>

          <table className="table table-bordered myparceltable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">REFERENCE</th>
              <th scope="col">SEND FROM</th>
              <th scope="col">SEND TO</th>
              <th scope="col">STATUS</th>
              <th scope="col">CREATED</th>
              <th scope="col">PRICE</th>
              {/* <th scope="col">STATUS</th> */}
              <th scope="col" colSpan='3'>QUICK SOLUTIONS</th>
            </tr>
          </thead>
          <tbody>

         {
              [...Array(parcelcount)].map((e, i) =><>
{parcelcontent && parcelcontent[i]!=''?(


<tr key={i}>
            <td><a className="awbtd"  onClick={()=>getTracking(parcelcontent[i].awbno)}>{parcelcontent[i].awbno}</a></td>
            {parcelcontent[i].delivery_from=='DXB' || parcelcontent[i].delivery_from=='AUH' || parcelcontent[i].delivery_from.length>2 ?(
              <td>{parcelcontent[i].delivery_from}</td>
            ):(
<td>{countries[`${parcelcontent[i].delivery_from}`]}</td>
            )
}

{parcelcontent[i].delivery_from=='DXB' || parcelcontent[i].delivery_from=='AUH'|| parcelcontent[i].delivery_from.length>2 ?(
              <td>{parcelcontent[i].delivery_to}</td>
            ):(
<td>{countries[`${parcelcontent[i].delivery_to}`]}</td>
            )
}
              
<td>{parcelcontent[i].transit_status}</td>
              <td>{parcelcontent[i].updated_on}</td>
              <td>AED {parcelcontent[i].total_price}</td>
              {/* <td>{getstatus(parcelcontent[i].awbno)} </td> */}
              <td> <a onClick={()=>OrderDelete(`${parcelcontent[i].id}`)} style={{backgroundColor: 'snow', border: 'none',color: '#d85b12', fontSize: '20px'}}> {trashicn}</a></td>
            <td> <a onClick={()=>setModalIsOpenToTrue(`${parcelcontent[i].awbno}`)} className="receipt"  style={{backgroundColor: 'snow', border: 'none',color: '#d85b12', fontSize: '20px'}}> {receipticn}</a></td>
            
            <td> <a onClick={()=>setModalIsOpenToTrue2(`${parcelcontent[i].awbno}`)} className="invoice"  style={{backgroundColor: 'snow', border: 'none',color: '#d85b12', fontSize: '20px'}}> {invoiceicn}</a></td>
            </tr>
):null

}</>  
             )
            } 

 
           
          </tbody>
        </table>
        <div className="pagination-sec fw"> 
        <nav aria-label="Page navigation">
          <ul className="pagination">
          {current>5?(<>
      
            <li>
              <a onClick={()=>nextpage(page-1)} aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
         
              <li>
              <a className="page_list" onClick={()=>nextpage(page-1)}>{page}</a>
            </li>
            </>
            )
          :null}
            
            {/*?php
                            }?*/}
            <li>
              <a className="page_list active" onClick={()=>nextpage(page)} >{page+1}</a>
            </li>
           {current<parcelcount2?(
<>
<li>
              <a className="page_list" onClick={()=>nextpage(page+1)}>{page+2}</a>
            </li>
            <li>
              <a onClick={()=>nextpage(page+1)}  aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
</>
           ):null}
           
            {/*?php
                            }?*/}
          </ul>
        </nav>
      </div>
        
       
      
        </div> {/* /#admin-main-control */}
      </div> {/* /.row */}
    </div> {/* /.container-fluid */}
    </div>
  </div>
  )
}


